import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import './App.css'
import { ToastContainer } from 'react-toastify'
import AntiBotMembers from './admin/AntiBotMembers/AntiBotMembers'
import ChooseMain from './admin/ChooseServer/Main'
import HolderVerification from './admin/HolderVerification/HolderVerification'
import AdminMain from './admin/Main'
import NotFound from './admin/NotFound/NotFound'
import Staking from './admin/StakingSetup/Staking'
import TokenCreation from './admin/TokenCreation/TokenCreation'
import ErrorPage from './components/Error/Error'
import MoreFeature from './components/Feature/MoreFeatures'
import Main from './components/Main/Main'
import 'react-toastify/dist/ReactToastify.min.css'
import DecentralizedStorage from './admin/DecentralizedStorage/DecentralizedStorage'
import RevenueSharing from './admin/RevenueSharing/RevenueSharing'
import VotingSystem from './admin/VotingSystem/VotingSystem'
import ChooseServer from './admin/ChooseServer/ChooseServer'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/allfeatures" element={<MoreFeature />} />
        <Route path="/select-server" element={<ChooseMain />} />
        <Route path="/dashboard" element={<AdminMain />}>
          <Route path="antiBotMembers" element={<AntiBotMembers />} />
          <Route path="HolderVerification" element={<HolderVerification />} />
          <Route path="Staking" element={<Staking />} />
          <Route path="TokenCreation" element={<TokenCreation />} />
          <Route path="RevenueSharing" element={<RevenueSharing />} />
          <Route
            path="DecentralizeStorage"
            element={<DecentralizedStorage />}
          />
          <Route path="VotingSystem" element={<VotingSystem />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/dashboard/not-found" />} />
          <Route path="addtektools" element={<ChooseServer />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  )
}

export default App
