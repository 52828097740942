import { MdKeyboardArrowDown } from "react-icons/md";
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Input,
  Switch,
  Select,
  Option,
} from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { BiX } from 'react-icons/bi'
import ButtonEdit from './_components/ButtonEdit'
import RoleAttibute from './_components/RoleAttibute'
import RoleQuantity from './_components/RoleQuantity'
import TableQuantity from './_components/TableQuantity'
import TableTrait from './_components/TableTrait'
import { ToastContainer, toast } from 'react-toastify';

const EditHolderVerification = ({ selectedCard, updateLoadCollection }) => {
  const [cardSelected, setCardSelected] = useState(selectedCard)
  const [checked, setChecked] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [tempChecked, setTempChecked] = useState(false)
  const [salesStatus, setSalesStatus] = useState(cardSelected.collection.Sales)
  const [listingsStatus, setListingsStatus] = useState(cardSelected.collection.Listing)
  const [editHolderRole, setEditHolderRole] = useState(false)
  const [quantitys, setQuantitys] = useState([])
  const [attributes, setAttributes] = useState([])
  const [dataRoleQty, setDataRoleQty] = useState([])
  const [dataRoleAttribute, setDataRoleAttribute] = useState([])
  const [traitList, setTraitList] = useState(true)
  const [editAddress, setEditAddress] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editRoles, setEditRoles] = useState(false)
  const [holderRole, setHolderRole] = useState(
    cardSelected.collection.HolderRole
  )
  console.log(cardSelected)
  const [tempCollectionNames, setTempCollectionNames] = useState(
    cardSelected.collection.DisplayName
  )
  const [tempVerifiedAddresses, setTempVerifiedAddresses] = useState(
    cardSelected.collection.VerifiedAddress
  )
  const [roles, setRoles] = useState([])

  console.log('roles', roles)

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [roles.length])

  useEffect(() => {
    if (cardSelected?.collection) {
      const perQty = cardSelected.collection.RolePerQuantity.flatMap((qty) => ({
        numberOfNfts: qty?.NumberOfNfts,
        roleId: qty?.RoleId,
        roleName: qty?.RoleName,
      }))
      setQuantitys([...perQty])
    }
  }, [cardSelected])

  useEffect(() => {
    if (cardSelected?.collection?.RolePerAttribute.length > 0) {
      const perAttr = cardSelected.collection.RolePerAttribute.flatMap(
        (attr) => ({
          roleId: attr?.RoleId,
          roleName: attr?.RoleName,
          attributeName: attr?.AttributeName,
          attributeValue: attr?.AttributeValue,
        })
      )
      setAttributes([...perAttr])
      setTraitList(true)
    } else {
      setTraitList(false)
    }
  }, [cardSelected])

  const handleAddQuantity = (newQty) => {
    const updatedQuantitys = [...quantitys, newQty]
    setQuantitys(updatedQuantitys)
    setDataRoleQty([...dataRoleQty, newQty])
  }

  const handleConfirmSwitchChange = () => {
    setChecked(tempChecked)
    setOpenDialog(false)
  }

  const handleCancelSwitchChange = () => {
    setTempChecked(checked)
    setOpenDialog(false)
  }

  const handleAddAttribute = (newAttr) => {
    const updatedAttributes = [...attributes, newAttr]
    setAttributes(updatedAttributes)
  }

  const handleTempCollectionNameChange = (newName) => {
    setTempCollectionNames(newName)
  }

  const handleTempVerifiedAddressChange = (newAddress) => {
    setTempVerifiedAddresses(newAddress)
  }

  const handleDeleteQuantity = (id) => {
    const updatedQuantitys = quantitys.filter((qty, index) => index !== id)
    setQuantitys(updatedQuantitys)
  }

  const handleDeleteAttribute = (id) => {
    const updatedAttributes = attributes.filter((attr, index) => index !== id)
    setAttributes(updatedAttributes)
  }

  const updateCollection = async () => {
    const updateCollection = {
      DisplayName: tempCollectionNames,
      VerifiedAddress: tempVerifiedAddresses,
      RolePerQuantity: quantitys.map(qty => ({
        numberOfNfts: qty.numberOfNfts,
        roleId: qty.roleId,
        roleName: qty.roleName,
      })),
      RolePerAttribute: attributes.map(attr => ({
        roleId: attr.roleId,
        roleName: attr.roleName,
        attributeName: attr.attributeName,
        attributeValue: attr.attributeValue,
      })),
      HolderRole: holderRole,
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}/collections/notification-info?guildId=${localStorage.getItem('selectedServer')}&collectionId=${cardSelected.collection._id}`
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateCollection),
      })
      if (!response.ok) {
        throw new Error('Failed to update collections')
      }
      const data = await response.json()
      toast.success('Collection updated successfully!')
      updateLoadCollection()
    setTimeout(() => {
      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
    }, 1000);
    } catch (error) {
      console.error('Error updating collections:', error)
      toast.error(`Opss, something gone wrong: ${error.message}`)
    }
  }

  console.log(cardSelected)
  return (
    <>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
      <div className="w-full h-16 flex flex-col">
        <div className="w-full h-full bg-third">
          <h1 className="text-2xl font-bold h-full flex items-end pl-14">Edit {cardSelected.collection.IsToken ? 'Token' : 'NFT'} Collection</h1>
        </div>
        <div className="w-full flex">
          <div className="w-1/3 h-4 bg-third rounded-br "></div>
        </div>
      </div>
      <div className="absolute top-0 right-0  items-center justify-center flex ">
        <Button
          className="text-2xl"
          variant="text"
          color="red"
          size="sm"
          onClick={() =>
            document.dispatchEvent(
              new KeyboardEvent('keydown', { key: 'Escape' })
            )
          }
        >
          <BiX />
        </Button>
      </div>
      <section className="max-h-[80vh] w-full container mx-auto transition-all duration-300 overflow-y-auto rounded-lg border border-second scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
        <div className="flex flex-col gap-y-5 p-5 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic ">
          <div className="p-5 bg-third rounded-lg">
            {/* Collection Name */}
            <div className="w-full ">
              <div className='w-full flex justify-end'>
                <span className='flex w-1/3 items-end  font-semibold'>Collection Name</span>
                <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                  <Input
                    placeholder="Exemple: 0x1234567890123456789012345678901234567890"
                    color="deep-purple"
                    variant="static"
                    className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                    textColor="white"
                    size="lg"
                    containerProps={{
                      className: 'bg-second ',
                    }}
                    labelProps={{
                      className:
                        'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                      style: {
                        color: 'white'
                      }
                    }}
                    value={tempCollectionNames}
                    onChange={(e) =>
                      handleTempCollectionNameChange(e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            {/* Creator Address */}
            <div className="w-full">
              <div className='w-full flex justify-end'>
                <span className='flex w-1/3 items-end  font-semibold'>Collection Address</span>
                <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                  <Input
                    placeholder="Exemple: 0x1234567890123456789012345678901234567890"
                    color="deep-purple"
                    variant="static"
                    className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                    textColor="white"
                    size="lg"
                    containerProps={{
                      className: 'bg-second ',
                    }}
                    labelProps={{
                      className:
                        'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                      style: {
                        color: 'white'
                      }
                    }}
                    value={tempVerifiedAddresses}
                    onChange={(e) =>
                      handleTempVerifiedAddressChange(e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            {/* Holder Role */}
            <div className='w-full flex justify-end'>
              <span className='flex w-1/3 items-end  font-semibold'>Holder Role</span>
              <div className="w-2/3 flex flex-col bg-second rounded-lg  ml-2 border border-third">
                {!editHolderRole ? (
                  <div className='w-full items-center flex justify-between'>
                    <div className='flex py-0.5 w-full text-white items-end justify-center'>
                      <Button variant="text" className="text-third w-full flex justify-between items-end text-end" onClick={() => setEditHolderRole((prev) => !prev)}>
                        <span className='text-white text-xs tracking-tighter capitalize'>{roles.find(role => role.id === holderRole)?.name}</span>

                        <MdKeyboardArrowDown size={20} />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Select
                    variant="standard"
                    color="deep-purple"
                    className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full justify-end flex text-white p-2   first-of-type:pl-4 "
                    textColor="white"
                    size="lg"
                    containerProps={{
                      className: 'bg-third ',
                    }}
                    labelProps={{
                      className:
                        'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                    }}
                    menuProps={{
                      className: 'text-white bg-third border-none font-semibold',
                    }}
                    open={true}
                    onChange={(selectedOption) => {
                      setHolderRole(selectedOption)
                    }}
                  >
                    {roles.map((role) => (
                      <Option
                        className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                        key={role.id}
                        value={role.id}
                      >
                        {role.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
              <div className="w-1/3 flex gap-x-5 px-5">
                <Switch
                  label="Sales"
                  color="purple"
                  checked={salesStatus}
                  onChange={(e) => setSalesStatus(e.target.checked)}
                />
                <Switch
                  label="Listings"
                  color="purple"
                  checked={listingsStatus}
                  onChange={(e) => setListingsStatus(e.target.checked)}
                />
              </div>
            </div>
          </div>

          {/* section tables */}
          <div className="w-full flex flex-col pb-5 bg-basic rounded-lg ">
            <div className="flex w-full items-center justify-between bg-third rounded-t-lg pl-5">
              <h2 className="text-xl font-bold w-full ">Roles in collection</h2>
              <div className="flex justify-end">
                <ButtonEdit action={() => setEditRoles((prev) => !prev)} />
              </div>
            </div>
            <div className="p-5 ">
              {/* Roles */}
              {editRoles && (
                <div className="flex gap-x-5 py-5">
                  <div className="flex flex-col w-1/2 gap-y-3 ">
                    <h2>Role per quantity</h2>
                    <div className="h-full flex flex-col justify-end">
                      <RoleQuantity
                        addButton={handleAddQuantity}
                        dataRoleQty={setDataRoleQty}
                        confirmQty={quantitys}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 gap-y-3">
                    <h2>Role per attribute</h2>
                    <RoleAttibute
                      addButton={handleAddAttribute}
                      dataRoleAttribute={setDataRoleAttribute}
                      traits={attributes}
                      confirmAttribute={attributes}
                      creatorAddress={cardSelected.collection.VerifiedAddress}
                      traitList={traitList}
                    />
                  </div>
                </div>
              )}
              {/* Tables */}
              <div className="flex gap-x-5 max-h-[25vh] overflow-y-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
                <TableQuantity
                  quantitys={quantitys}
                  del={editRoles}
                  onClickDel={handleDeleteQuantity}
                />
                <TableTrait
                  traits={attributes}
                  del={editRoles}
                  onClickDel={handleDeleteAttribute}
                />
              </div>
            </div>
          </div>


          {/* Button save/close */}
          <div className="flex absolute bottom-3 right-3">
            <Button
              variant="text"
              color="red"
              onClick={() =>
                document.dispatchEvent(
                  new KeyboardEvent('keydown', { key: 'Escape' })
                )
              }
            >
              Cancel
            </Button>
            <Button variant="gradient" color="deep-purple"
              // onClick={handleSave}
              onClick={updateCollection}
            >
              Save
            </Button>
          </div>
        </div>
      </section>
      <div className="flex flex-col w-full h-16 ">
        <div className="w-full flex justify-end ">
          <div className="w-1/3 h-4 bg-third rounded-tl shadow-sm shadow-shadow"></div>
        </div>
        <div className="bg-third h-full"></div>
      </div>
      <Dialog
        className="text-white bg-third"
        open={openDialog}
        handler={setOpenDialog}
      >
        <DialogHeader className="text-white">
          {' '}
          {checked
            ? 'Deseja alterar para Traits, será necessário definir novos atributos'
            : 'Deseja alterar para Token'}
        </DialogHeader>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleCancelSwitchChange}>
            Cancel
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleConfirmSwitchChange}
          >
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default EditHolderVerification
