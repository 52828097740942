import { Button, Input, Option, Select, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { GiReturnArrow } from 'react-icons/gi'
import { HiArrowUturnLeft } from 'react-icons/hi'
import { toast } from 'react-toastify'
import AddTrait from './_components/AddTrait'

const CreateRevenueSharing = ({ loadRevSharing, returnBotton }) => {
  const [isTraitTrue, setIsTraitTrue] = useState(false)
  const [nameToken, setNameToken] = useState('')
  const [amountPerNft, setAmountPerNft] = useState('')
  const [minimumStakedDays, setMinimumStakedDays] = useState('')
  const [endsInDays, setEndsInDays] = useState('')
  const [traitRewards, setTraitRewards] = useState([])
  const [listRevSharing, setListRevSharing] = useState([])
  const [collectionLists, setCollectionLists] = useState([])
  const [collectionSelected, setCollectionSelected] = useState('')

  const infoRevenueSharing = {
    GuildId: localStorage.getItem('selectedServer'),
    GuildName: listRevSharing ? listRevSharing.GuildName : '',
    TokenName: nameToken,
    CollectionVerifiedAddress: collectionSelected.VerifiedAddress,
    CollectionId: collectionSelected.Id,
    AmountPerNft: amountPerNft,
    MinimumStakedDays: minimumStakedDays,
    CreatedDate: new Date().toISOString(),
    ExpireDate: new Date(
      new Date().getTime() + endsInDays * 24 * 60 * 60 * 1000
    ).toISOString(),
    TraitRewards: isTraitTrue ? traitRewards : [],
  }

  useEffect(() => {
    const fetchCollectionList = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/collections/by-guild/${localStorage.getItem('selectedServer')}`
      )
      const data = await response.json()
      setCollectionLists(data)
    }
    fetchCollectionList()
  }, [])

  useEffect(() => {
    setListRevSharing(loadRevSharing)
  }, [loadRevSharing])

  const saveRevenueSharing = async () => {
    if (
      collectionSelected &&
      amountPerNft.trim() &&
      nameToken.trim() &&
      minimumStakedDays !== '' &&
      endsInDays !== ''
    ) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/revenue-sharing`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(infoRevenueSharing),
        }
      )
      if (response.ok) {
        console.log('Revenue sharing saved successfully', response)
      } else {
        console.error('Failed to save revenue sharing', response)
      }
    } else {
      toast.error('Please fill in all the fields')
    }
  }

  const handleCollectionChange = (selectedId) => {
    const selectedCollection = collectionLists.find(
      (collection) => collection.Id === selectedId
    )
    setCollectionSelected(selectedCollection)
  }

  return (
    <>
      <section className="container mx-auto text-white">
        <div className="flex flex-col gap-y-5">
          <div className="text-4xl font-bold flex items-end justify-between">
            <span>Revenue Sharing</span>
            <div className="flex h-12  right-0">
              <Button
                variant="gradient"
                color="deep-purple"
                className="h-12 w-12 p-2 flex items-center justify-center"
                onClick={returnBotton}
              >
                <BsArrowReturnLeft className="text-xl" />
              </Button>
            </div>
          </div>
          <div className="text-lg">Setup your revenue sharing</div>
          {/* Name of your token */}
          <div className="flex gap-x-5">
            {/* nameToken */}
            <Input
              label="Enter the name of your token*"
              placeholder="Exemple: SOL."
              variant="outlined"
              color="deep-purple"
              size="lg"
              className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg"
              textColor="white"
              containerProps={{
                className: 'bg-third rounded-lg text-white font-bold',
              }}
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
              }}
              onChange={(e) => setNameToken(e.target.value)}
              value={nameToken}
            />
            {/* Amount per NFT */}
            <Input
              label="Amount per NFT*"
              placeholder="Example: 0.05"
              variant="outlined"
              color="deep-purple"
              size="lg"
              type="number"
              className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg"
              textColor="white"
              containerProps={{
                className: 'bg-third rounded-lg text-white font-bold',
              }}
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
              }}
              onChange={(e) => setAmountPerNft(e.target.value)}
              value={amountPerNft}
            />
          </div>
          <div className="flex gap-x-5">
            {/* Collection */}
            <Select
              variant="outlined"
              color="deep-purple"
              size="lg"
              className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg"
              textColor="white"
              containerProps={{
                className: 'bg-third rounded-lg text-white font-bold',
              }}
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
              }}
              menuProps={{
                className: 'text-white bg-third border-none font-semibold ',
              }}
              label="Collection address that will receive revenue*"
              onChange={(value) => handleCollectionChange(value)}
              value={collectionSelected.Id}
            >
              {collectionLists.map((collection) => (
                <Option
                  key={collection.Id}
                  value={collection.Id}
                  className="hover:bg-second hover:text-white hover:pl-7 transition-all duration-300"
                >
                  {collection.DisplayName} - {collection.VerifiedAddress}
                </Option>
              ))}
            </Select>
            {/* Minimum staked days */}
            <div className="flex w-full gap-x-5">
              <Select
                variant="outlined"
                color="deep-purple"
                size="lg"
                className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg"
                textColor="white"
                containerProps={{
                  className: 'bg-third rounded-lg text-white font-bold',
                }}
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
                }}
                menuProps={{
                  className: 'text-white bg-third border-none font-semibold ',
                }}
                label="Minimum staked days*"
                onChange={(value) => setMinimumStakedDays(value)}
                type="number"
              >
                <Option
                  value={0}
                  className="hover:bg-second hover:text-white hover:pl-7 transition-all duration-300"
                >
                  0
                </Option>
                <Option
                  value={7}
                  className="hover:bg-second hover:text-white hover:pl-7 transition-all duration-300"
                >
                  7
                </Option>
              </Select>
              {/* Ends in days */}
              <Input
                label="Ends is days*"
                placeholder="Example: 2"
                variant="outlined"
                color="deep-purple"
                size="lg"
                className="focus:outline-none active:border-spacing-1  bg-third focus:bg-basic w-full text-white px-2 tracking-widest font-bold text-xl"
                containerProps={{
                  className: 'bg-third rounded-lg text-white font-bold text-lg',
                }}
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text- placeholder-shown:text-white text-white font-bold ',
                }}
                onChange={(e) => setEndsInDays(e.target.value)}
                value={endsInDays}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-10 ">
            {collectionSelected.VerifiedAddress && (
              <div className="flex w-full gap-x-5 justify-end">
                <div className="text-sm font-bold">
                  {isTraitTrue ? 'Disable' : 'Enable'} trait rewards
                </div>
                <Switch
                  color="deep-purple"
                  className="ml-auto"
                  onChange={(e) => setIsTraitTrue(e.target.checked)}
                />
              </div>
            )}
            {isTraitTrue && (
              <AddTrait
                creatorAddress={collectionSelected.VerifiedAddress}
                onTraitRewardsChange={setTraitRewards}
              />
            )}
          </div>

          {/* Button section */}
          <hr className="h-px my-5 bg-gray border-0" />
          <div className="flex justify-between">
            <Button
              className="bg-third text-white px-14 shadow-md shadow-shadow hover:bg-basic"
              onClick={() => {
                setNameToken('')
                setAmountPerNft('')
                setMinimumStakedDays('')
                setEndsInDays('')
                setIsTraitTrue(false)
                setTraitRewards([])
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-accentMain text-white px-10 shadow-md shadow-shadow hover:bg-accentHouver"
              onClick={saveRevenueSharing}
            >
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateRevenueSharing
