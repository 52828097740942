import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import CreateRevenueSharing from './CreateRevenueSharing'
import LoadRevenueSharing from './LoadRevenueSharing'

const RevenueSharing = () => {
  const [revSharingInfo, setRevSharindInfo] = useState()
  const [newRevSharing, setNewRevSharing] = useState(false)
  return (
    <>
      <section className="">
        <div className="flex flex-col gap-y-5 ">
          {!newRevSharing ? (
            <div className="flex container mx-auto">
              <LoadRevenueSharing onListRevSharing={setRevSharindInfo} />
              <div className="flex h-14 w-full p-3 justify-end">
                <Button
                  variant="gradient"
                  color="deep-purple"
                  className="h-full w-auto flex p-5 items-center"
                  onClick={() => setNewRevSharing(true)}
                >
                  Create New
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex container mx-auto">
              <CreateRevenueSharing
                loadRevSharing={revSharingInfo}
                returnBotton={() => setNewRevSharing(false)}
              />
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default RevenueSharing
