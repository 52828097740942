import React, { useState } from 'react'
import StakingCreated from './StakingCreated'
import StakingSetup from './StakingSetup'
import { Dialog, Button } from '@material-tailwind/react'


const Staking = () => {
  const [stakingList, setStakingList] = useState([])
  const [close, setClose] = useState(false)


  const handleClose = () => {
    setClose(!close)
  }

  return (
    <div className="container mx-auto h-full text-white py-5">
      <div className="flex flex-col gap-y-5">
        <div className='flex justify-between items-start'>
          <h1 className="text-4xl pb-14 font-bold">Staking Setup</h1>
          <Button color='deep-purple' className='bg-main' onClick={handleClose}>Add Staking</Button>
        </div>
        <div className="flex flex-col w-full items-center justify-center gap-y-5">
          <StakingCreated staking={stakingList} setStaking={setStakingList} />

          <Dialog
            open={close}
            handler={handleClose}
            className="flex flex-col h-[90vh] text-white bg-bgAdmin overflow-y-scroll overflow-hidden "
            size="lg"
            animate={{
              mount: { y: 0 },
              unmount: { y: -200 },
            }}
          >
            <StakingSetup />
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export default Staking
