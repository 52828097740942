import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()
const MultiselectChannel = ({ onSelectChannel, qntSelect, isMulti }) => {
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const maxSelectedOptions = qntSelect
  const isValidNewOption = (inputValue, selectValue) => {
    return selectValue.length < maxSelectedOptions
  }
  //chamada API para listar as Channels
  useEffect(() => {
    const fetchChannels = async () => {
      setIsLoading(true)
      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/channels/${localStorage.getItem('selectedServer')}`
        // const apiUrl = `/channels/${localStorage.getItem('selectedServer')}`
        const response = await fetch(apiUrl)
        const data = await response.json()
        setChannels(data)
      } catch (error) {
        console.error('Erro ao buscar roles:', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (channels.length === 0) {
      fetchChannels()
    }
  }, [])

  const dropdownOptions = channels.map((channel) => ({
    value: channel.id,
    label: channel.name,
  }))

  //seleciona multiselect
  const handleSelectChannel = (selectedOptions) => {
    if (selectedOptions.length > maxSelectedOptions) {
      selectedOptions = selectedOptions.slice(0, maxSelectedOptions)
    }
    setSelectedChannel(selectedOptions)
    onSelectChannel(selectedOptions)
  }

  return (
    <div className="flex flex-col w-full gap-y-1">
      <span className="px-3 text-base font-thin">Select channel</span>
      <Select
        placeholder="Select channel"
        isLoading={isLoading}
        isValidNewOption={isValidNewOption}
        components={animatedComponents}
        isMulti={isMulti}
        options={dropdownOptions}
        value={selectedChannel}
        onChange={handleSelectChannel}
        styles={{
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#393D5E',
            color: '#fff',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: state.hasValue ? '0.5rem 0' : '0.5rem',
          }),
          container: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            border: 0,
          }),
          control: (provided, state) => ({
            ...provided,
            height: 48,
            maxWidth: '100%',
            backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
            color: '#fff',
            borderRadius: 8,
            borderColor: state.isFocused ? '#9945ff' : '#fff',
            boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
            paddingLeft: '1rem',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#9945ff'
              : state.isFocused
                ? '#9945ff'
                : '#393D5E',
            color: '#fff',
            cursor: 'pointer',
            padding: 0,
            paddingLeft: '1rem',
            margin: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#9945ff',
            color: '#fff',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
          }),
        }}
      />
    </div>
  )
}

export default MultiselectChannel
