import { Alert, Button, Option, Select } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'

const RoleAttibute = ({
  dataRoleAttribute,
  confirmAttribute,
  creatorAddress,
  addButton,
  traitList,
}) => {
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [roleAttribute, setRoleAttribute] = useState([])
  const [alert, setAlert] = useState({ show: false, message: '', type: '' })
  const [traits, setTraits] = useState([])
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [roles.length])

  useEffect(() => {
    if (creatorAddress) {
      const fetchTraits = async () => {
        const apiUrlTraits = `${process.env.REACT_APP_API_BASE_URL}/collections/${creatorAddress}/traits`
        const response = await fetch(apiUrlTraits)
        const data = await response.json()
        setTraits(data)
      }
      fetchTraits()
    }
  }, [creatorAddress])

  const handleAddRoleAttribute = () => {
    if (selectedRole) {
      const newRoleAttribute = {
        roleId: selectedRole.id,
        roleName: selectedRole.name,
        attributeName: selectedTraitType,
        attributeValue: selectedTraitValue,
      }
      const exists = confirmAttribute.some(
        (item) =>
          item.roleId === newRoleAttribute.roleId &&
          item.attributeName === newRoleAttribute.attributeName &&
          item.attributeValue === newRoleAttribute.attributeValue
      )
      if (!exists) {
        const updatedRoleAttribute = [...roleAttribute, newRoleAttribute]
        setRoleAttribute(updatedRoleAttribute)
        dataRoleAttribute(updatedRoleAttribute)
        addButton(newRoleAttribute)
      } else {
        setAlert({ show: true, message: 'Atributo já existe', type: 'red' })
        setTimeout(() => {
          setAlert({ show: false, message: '', type: '' })
        }, 1000)
      }
    }
  }


  return (
    <div className="flex flex-col h-full w-full gap-y-5">
      {traits.statusCode !== 404 ? (
        <>
          <div className="flex gap-x-5">
            <Select
              variant="static"
              color="deep-purple"
              className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white px-2"
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
              }}
              label="Select Trait Type"
              onChange={(value) => {
                const selected = traits.find(
                  (trait) => trait.TraitType === value
                )
                setSelectedTraitType(selected.TraitType)
              }}
            >
              {traits.map((trait) => (
                <Option key={trait.TraitType} value={trait.TraitType}>
                  {trait.TraitType}
                </Option>
              ))}
            </Select>
            <Select
              variant="static"
              color="deep-purple"
              className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white px-2"
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
              }}
              label="Select Trait Value"
              onChange={(value) => {
                setSelectedTraitValue(value)
              }}
            >
              {traits
                .filter((trait) => trait.TraitType === selectedTraitType)
                .flatMap((trait) => trait.TraitValue) // Flatten the array of TraitValue
                .map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex w-full gap-x-5">
            {alert.show && (
              <div className="absolute bottom-4 left-4 z-50">
                <Alert color={alert.type}>{alert.message}</Alert>
              </div>
            )}
            <Select
              variant="static"
              color="deep-purple"
              className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white px-2"
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
              }}
              label="Select Role"
              onChange={(value) => {
                const selected = roles.find((role) => role.id === value)
                setSelectedRole({ id: selected.id, name: selected.name })
              }}
            >
              {roles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
            <div className="flex h-full">
              <Button
                onClick={handleAddRoleAttribute}
                className="bg-mainHover p-2 h-full "
              >
                ADD
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h1 className="text-white text-xl">No traits found</h1>
        </div>
      )}
    </div>
  )
}

export default RoleAttibute
