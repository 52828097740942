import React, { useState, useEffect } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import Button from '../../components/ui/button'
import Input from '../../components/ui/input'

const InputTokenTotal = ({ value, onChange }) => {
  const [quantity, setQuantity] = useState(value || '')

  useEffect(() => {
    setQuantity(value)
  }, [value])

  const handleChangeQuantity = (event) => {
    const newValue = event.target.value
    setQuantity(newValue)
    onChange(newValue)
  }

  const handleDecrement = () => {
    const newValue = Math.max(parseInt(quantity) - 1, 1)
    setQuantity(newValue)
    onChange(newValue)
  }

  const handleIncrement = () => {
    const newValue = Math.min(parseInt(quantity) + 1, 1000000)
    setQuantity(newValue)
    onChange(newValue)
  }

  return (
    <div className="flex flex-col gap-y-1 gap-x-5 text-base font-thin">
      <span className="px-3">Enter the total you wish to create*</span>
      <div className="flex flex-row gap-x-5">
        <div className="flex border rounded-lg bg-accent">
          <Button
            onClick={handleDecrement}
            type="button"
            className="w-[45px] h-full"
          >
            <AiOutlineMinus />
          </Button>
          <Input
            type="text"
            value={quantity}
            onChange={handleChangeQuantity}
            placeholder="Exemple: 1000000"
            className="text-center px-0 rounded-none border-none"
          />
          <Button
            onClick={handleIncrement}
            type="button"
            className="w-[45px] h-full"
          >
            <AiOutlinePlus />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InputTokenTotal
