import { Button, Input, Option, Select } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineRemove } from 'react-icons/md'
import { MdOutlineAdd } from 'react-icons/md'
import { toast } from 'react-toastify'

const TraitsAdd = ({ CollectionAddress, newTrait }) => {
  const Address = CollectionAddress
  const [traits, setTraits] = useState([])
  const [selectedTraitType, setSelectedTraitType] = useState('')
  const [selectedTraitValue, setSelectedTraitValue] = useState('')
  const [traitValues, setTraitValues] = useState([])
  const [value, setValue] = useState(1)

  useEffect(() => {
    const traitsList = async () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/collections/${Address}/traits`
      )
        .then((response) => response.json())
        .then((data) => {
          setTraits(data)
        })
        .catch((error) => {
          console.error('Error fetching traits:', error)
        })
    }
    traitsList()
  }, [Address])

  useEffect(() => {
    if (selectedTraitType) {
      const selectedAttribute = traits.find(
        (attr) => attr.TraitType === selectedTraitType
      )
      if (selectedAttribute) {
        const values = selectedAttribute.TraitValue.map((value) => ({
          label: value,
          value: value,
          id: selectedAttribute._id,
        }))
        setTraitValues(values)
        setSelectedTraitValue('')
      } else {
        setTraitValues([])
      }
    }
  }, [selectedTraitType, traits])

  const increment = () => setValue(value + 1)
  const decrement = () => {
    if (value <= 1) {
      setValue(1)
    } else {
      setValue(value - 1)
    }
  }

  const handleAddTrait = () => {
    if (selectedTraitType && selectedTraitValue && value > 0) {
      const newTraitObj = {
        TraitType: selectedTraitType,
        TraitValue: selectedTraitValue,
        TraitReward: value,
      }
      newTrait((prevTraits) => {
        // Verifica se o trait já existe na lista
        const exists = prevTraits.some(
          (trait) =>
            trait.TraitType === newTraitObj.TraitType &&
            trait.TraitValue === newTraitObj.TraitValue
        )
        if (exists) {
          toast.error('Trait already exists')
          return prevTraits // Retorna a lista sem adicionar o novo trait
        }
        return [...prevTraits, newTraitObj] // Adiciona o novo trait
      })
    } else {
      toast.error('Please select a trait type and value')
    }
  }

  return (
    <div className="flex w-auto gap-x-2 text-white">
      <div className="flex flex-row gap-x-2 w-full">
        <Select
          variant="static"
          color="deep-purple"
          className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white px-2"
          labelProps={{
            className:
              'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
          }}
          label="Traits Type"
          onChange={(value) => {
            setSelectedTraitType(value)
          }}
        >
          {traits.map((trait) => (
            <Option key={trait._id} value={trait.TraitType}>
              {trait.TraitType}
            </Option>
          ))}
        </Select>
        <Select
          variant="static"
          color="deep-purple"
          className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white px-2 "
          labelProps={{
            className:
              'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
          }}
          label="Traits Value"
          onChange={(value) => setSelectedTraitValue(value)}
        >
          {traitValues.map((traitValue) => (
            <Option key={traitValue.id} value={traitValue.value}>
              {traitValue.label}
            </Option>
          ))}
        </Select>
      </div>
      <div className="flex items-end gap-x-0.5 w-20 transition duration-300">
        <input
          type="number"
          min={1}
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
          className="w-14 bg-transparent border-white/60 border-b focus:border-mainHover focus-within:outline-none px-2 py-1 text-center "
        />
        <div className="h-full flex flex-col gap-y-0.5 items-center justify-center">
          <Button
            onClick={increment}
            className="bg-mainHover p-1 h-1/2 rounded-b"
          >
            <MdOutlineAdd />
          </Button>
          <Button
            onClick={decrement}
            className="bg-mainHover p-1 h-1/2 rounded-t"
          >
            <MdOutlineRemove />
          </Button>
        </div>
        <div className="h-full">
          <Button
            onClick={handleAddTrait}
            className="bg-mainHover p-2 h-full w-full "
          >
            ADD
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TraitsAdd
