import React from 'react'
import { CgTrash } from 'react-icons/cg'
import Button from '../../components/ui/button'

const TableQuantity = ({ quantitys, onClickDel, del }) => {
  const tableHidden = quantitys.length === 0 ? 'hidden' : ''

  return (
    <div
      className={`w-full overflow-y-auto shadow-md rounded-lg border border-second scrollbar-thin scrollbar-thumb-second scrollbar-track-basic ${tableHidden}`}
    >
      <table className="w-full text-left h-full">
        <thead className="text-xs bg-third uppercase">
          <tr>
            <th scope="col" className="hidden text-center py-2">
              RoleId
            </th>
            <th scope="col" className="text-center py-2">
              RoleName
            </th>
            <th scope="col" className="text-center py-2 w-auto ">
              Quantity NFTs
            </th>
            <th scope="col" className="text-center py-2 "></th>
          </tr>
        </thead>
        <tbody className="overflow-y-scroll scrollbar-thin scrollbar-thumb-second scrollbar-track-basic h-full">
          {quantitys.map((quantity, index) => (
            <tr
              key={index}
              className="bg-basic hover:bg-third border-second border-t font-light "
            >
              <td className="hidden px-3 border-r border-second">
                {quantity.roleId}
              </td>
              <td className="px-3 border-r border-second">
                {quantity.roleName}
              </td>
              <td className="p-3 border-r border-second text-center ">
                {quantity.numberOfNfts}
              </td>
              {del && (
                <td className="w-12 hover:bg-accentHover ">
                  <Button
                    onClick={() => onClickDel(index)}
                    type="button"
                    className="flex w-full text-[35px] font-thin bg-main"
                  >
                    <CgTrash />
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableQuantity
