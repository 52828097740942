import { Button, Input, Option, Select, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { MdOutlineAdd, MdOutlineRemove, MdOutlineKeyboardArrowDown } from 'react-icons/md'

const TokenSetup = ({
  onRoleSelect,
  onQuantitySelect,
  onTokenAddress,
  onCollectionName,
  onSkipRole,
}) => {
  const [value, setValue] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [tokenAddress, setTokenAddress] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [isSkipRole, setIsSkipRole] = useState(false)

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [])

  const increment = () => setValue(value + 1)
  const decrement = () => {
    if (value <= 1) {
      setValue(1)
    } else {
      setValue(value - 1)
    }
  }

  useEffect(() => {
    onQuantitySelect(value)
  }, [value, onQuantitySelect])

  useEffect(() => {
    onRoleSelect(selectedRole)
  }, [selectedRole, onRoleSelect])

  useEffect(() => {
    onCollectionName(collectionName)
  }, [collectionName, onCollectionName])

  useEffect(() => {
    onTokenAddress(tokenAddress)
  }, [tokenAddress, onTokenAddress])

  useEffect(() => {
    onSkipRole(isSkipRole)
  }, [isSkipRole, onSkipRole])


  return (
    <>
      <div className="flex flex-col gap-y-5">
        {/* Collection Name */}
        <div className='w-full flex flex-col justify-end'>
          <span className='text-sm font-semibold'>Enter a name of Collection*</span>
          <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
            <Input
              placeholder="Exemple: My Collection."
              color="deep-purple"
              variant="static"
              className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none "
              textColor="white"
              size="lg"
              containerProps={{
                className: 'bg-second ',
              }}
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                style: {
                  color: 'white'
                }
              }}
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
            />
          </div>
        </div>
        {/* Address */}
        <div className='w-full flex flex-col justify-end'>
          <span className='text-sm font-semibold'>Enter a token address*</span>
          <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
            <Input
              placeholder="Exemple: 0x1234567890123456789012345678901234567890"
              color="deep-purple"
              variant="static"
              className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
              textColor="white"
              size="lg"
              containerProps={{
                className: 'bg-second ',
              }}
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                style: {
                  color: 'white'
                }
              }}
              value={tokenAddress}
              onChange={(e) => setTokenAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full flex gap-x-5 justify-end">
          {/* Quantity input token */}
          <div className=' w-full flex items-end justify-end'>
            <div className='w-full  flex items-center justify-end bg-third p-px rounded-lg'>
              <span className='w-full px-2'>Quantity of tokens:</span>
              <div className={`flex items-end gap-x-0.5 transition duration-300 bg-basic rounded-lg h-full p-1 selection:bg-second  ${value ? 'bg-second' : ''}`}>
                <input
                  type="number"
                  min={1}
                  value={value}
                  onChange={(e) => {
                    setValue(Number.parseInt(e.target.value))
                  }}
                  className="w-full bg-transparent focus-within:outline-none px-4 py-1 m-1 "
                />
                <div className="h-full flex flex-col gap-y-0.5 items-center justify-center">
                  <Button
                    onClick={increment}
                    className="bg-mainHover p-1 h-1/2 rounded-b"
                  >
                    <MdOutlineAdd />
                  </Button>
                  <Button
                    onClick={decrement}
                    className="bg-mainHover p-1 h-1/2 rounded-t"
                  >
                    <MdOutlineRemove />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* Quantity input role */}
          <div className='w-full flex flex-col justify-end'>
            <span className='text-sm'>Select a Role</span>
            <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
              <Select
                variant="standard"
                color="deep-purple"
                className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                size="lg"
                containerProps={{
                  className: 'bg-third ',
                }}
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                }}
                menuProps={{
                  className: 'text-white bg-third border-none font-semibold',
                }}

                onChange={(value) => {
                  const selected = roles.find((role) => role.id === value)
                  setSelectedRole({ id: selected.id, name: selected.name })
                }}

              >
                {roles.map((role) => (
                  <Option
                    className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                    key={role.id}
                    value={role.id}
                  >
                    {role.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {/* Skip Role */}
          <div className='flex w-full justify-between items-center'>
            <span className=" font-semibold">Do you want to skip role?</span>
            <Switch
              className={`${isSkipRole ? 'bg-mainHover' : ''}`}
              label={`${isSkipRole ? 'Yes' : 'No'}`}
              checked={isSkipRole}
              onChange={() => setIsSkipRole(!isSkipRole)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TokenSetup
