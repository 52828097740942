import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()
const SelectTrait = ({
  onSelectTraitType,
  onSelectTraitValue,
  creatorAddress,
}) => {
  const [trait, setTrait] = useState([])
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [traitValue, setTraitValue] = useState([])
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!creatorAddress || creatorAddress === '') {
      return
    } else {
      const fetchTrait = async () => {
        const api = `${process.env.REACT_APP_API_BASE_URL}/collections/${creatorAddress}/traits`
        const response = await fetch(api)
        const data = await response.json()
        setTrait(data)
        setIsLoading(false)
      }
      fetchTrait()
    }
  }, [creatorAddress])

  const handleSelectTraitType = (e) => {
    setSelectedTraitType(e)
    setSelectedTraitValue(null)
    if (onSelectTraitType) {
      onSelectTraitType(e)
    }
    const selectedTrait = trait.find((t) => t.TraitType === e.value)
    setTraitValue(
      selectedTrait
        ? selectedTrait.TraitValue.map((value) => ({ value, label: value }))
        : []
    )
  }

  const handleSelectTraitValue = (e) => {
    setSelectedTraitValue(e)
    if (onSelectTraitValue) {
      onSelectTraitValue(e)
    }
  }

  const dropdownOptionsTraitType = Array.from(
    new Set(trait.map((traits) => traits.TraitType))
  ).map((traitType) => ({
    value: traitType,
    label: traitType,
  }))

  return (
    <div className="flex flex-row w-full gap-x-5">
      <div className="flex flex-col w-1/2 gap-y-1">
        <span className="px-3 text-base font-thin">Trait type</span>
        <Select
          placeholder="Enter a trait type"
          isLoading={isLoading}
          components={animatedComponents}
          options={dropdownOptionsTraitType}
          value={selectedTraitType}
          onChange={handleSelectTraitType}
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              padding: state.hasValue ? '0.5rem 0' : '0.5rem',
            }),
            container: (provided, state) => ({
              ...provided,
              margin: 0,
              padding: 0,
              border: 0,
            }),
            singleValue: (provided) => ({
              ...provided,
              color: '#fff',
            }),
            control: (provided, state) => ({
              ...provided,
              height: 48,
              maxWidth: '100%',
              backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
              color: '#fff',
              borderRadius: 8,
              borderColor: state.isFocused ? '#9945ff' : '#fff',
              boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
              paddingLeft: '1rem',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? '#9945ff'
                : state.isFocused
                  ? '#9945ff'
                  : '#393D5E',
              color: '#fff',
              cursor: 'pointer',
              padding: 0,
              paddingLeft: '1rem',
              margin: 0,
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: '#9945ff',
              color: '#fff',
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: '#fff',
            }),
          }}
        />
      </div>
      <div className="flex flex-col w-1/2 gap-y-1">
        <span className="px-3 text-base font-thin">Trait value</span>
        <Select
          placeholder="Enter a trait value"
          isLoading={isLoading}
          components={animatedComponents}
          options={traitValue}
          value={selectedTraitValue}
          onChange={handleSelectTraitValue}
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              padding: state.hasValue ? '0.5rem 0' : '0.5rem',
            }),
            container: (provided, state) => ({
              ...provided,
              margin: 0,
              padding: 0,
              border: 0,
            }),
            singleValue: (provided) => ({
              ...provided,
              color: '#fff',
            }),
            control: (provided, state) => ({
              ...provided,
              height: 48,
              maxWidth: '100%',
              backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
              color: '#fff',
              borderRadius: 8,
              borderColor: state.isFocused ? '#9945ff' : '#fff',
              boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
              paddingLeft: '1rem',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? '#9945ff'
                : state.isFocused
                  ? '#9945ff'
                  : '#393D5E',
              color: '#fff',
              cursor: 'pointer',
              padding: 0,
              paddingLeft: '1rem',
              margin: 0,
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: '#9945ff',
              color: '#fff',
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: '#fff',
            }),
          }}
        />
      </div>
    </div>
  )
}

export default SelectTrait
