import { Button, Card, Dialog, Switch } from '@material-tailwind/react'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi'
import EditHolderVerification from './EditHolderVerification'

const LoadHolderVerification = ({ updateTrigger }) => {
  const guildId = localStorage.getItem('selectedServer')
  const [holderList, setHolderList] = useState([])
  const [close, setClose] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const [serverList, setServerList] = useState([])

  const handleCardClick = (holder, collection) => {
    setSelectedCard({ holder, collection })
    handleClose()
  }

  const handleClose = () => {
    setClose((cur) => !cur)
  }

  const updateLoadCollection = () => {
    const HolderList = async () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/collections?guildId=${guildId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setHolderList(data)
        })
    }
    const ServerList = async () => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/roles/${guildId}`)
        .then((res) => res.json())
        .then((data) => {
          setServerList(data)
        })
    }
    HolderList()
    ServerList()
  }
  console.log('serverList', holderList)

  useEffect(() => {
    updateLoadCollection()
  }, [updateTrigger])

  return (
    <section className="flex flex-wrap gap-5 overflow-y-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic pb-10 items-center justify-center">
      {/* Card loaded with staking list */}
      {Array.isArray(holderList) && holderList.length > 0 && holderList.map((holder, holderIndex) =>
        holder.CollectionNotifications.map((collection, collectionIndex) => (
          <Card
            key={`${holderIndex}-${collectionIndex}`}
            className="h-60 w-80 group overflow-hidden transition-opacity hover:opacity-90 bg-second"
            
          >
            <div className="w-full h-full flex flex-row bg-accentMain rounded-lg">
              {/* Esquerda */}
              <div className="w-4/5 h-full flex flex-col bg-second shadow-md rounded-lg gap-x-2 transition-all duration-300">
                {/* Collection name */}
                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="w-full flex flex-row items-end gap-x-5 pt-5 pl-5"
                >
                  <span className="text-xl font-bold border-b-2 border-accentMain transition-all w-full pl-5">
                    {collection.DisplayName}
                  </span>
                </motion.div>
                <div className="w-full h-full flex flex-col items-end justify-start gap-y-5">
                  <div className="w-20 h-2 flex text-center items-center justify-center bg-accentMain rounded-bl-lg -mr-1 group-hover:w-44 transition-all duration-300"></div>
                  <div className="w-full h-full flex flex-row">
                    <div className="flex flex-col w-full items-center justify-center gap-y-5 pl-5">
                      {/* Creator Address id */}
                      <div className="flex flex-col w-full gap-x-2">
                        {collection.IsToken ? (
                          <h3 className="text-base">Token Address:</h3>
                        ) : (
                          <h3 className="text-base">Trait Collection Address:</h3>
                        )}
                        <h4 className="text-sm truncate pr-2 transition-all duration-300">
                          {collection.VerifiedAddress}
                        </h4>
                      </div>

                      {/*  Holder Role */}
                      <div className="flex flex-row w-full items-center">
                        <span className="text-sm flex gap-x-2">Holder role  {serverList.find((server) => server.id === collection.HolderRole)?.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-col w-full gap-x-2 items-start pl-5 pb-5"
                >
                  <Button
                    color="deep-purple"
                    className="flex flex-row items-center justify-center text-center gap-x-2 w-24 h-10 text-base font-bold px-2  bg-main transition-all duration-300 hover:bg-mainHover"
                    onClick={() => handleCardClick(holder, collection)}
                  >
                    <BiEdit className="text-lg" />
                    Edit
                  </Button>
                </motion.div>
              </div>

              {/* ______________________________________________________________________________________________ */}

              {/* Direita */}
              <div className="w-1/5 h-full flex flex-col items-start pl-3 justify-center gap-y-5 transition-all duration-300">
               
                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.7 }}
                  className='flex flex-col items-center justify-center gap-y-1'
                >
                  <span className='text-sm'>Sales</span>
                  {collection.Sales ? (
                    <Switch
                      className={`${collection.Sales ? 'bg-main' : 'bg-basic'}`}
                      checked={true}
                      onChange={() => {}}
                    />
                  ) : (
                    <Switch
                      className={`${collection.Sales ? 'bg-main' : 'bg-basic'}`}
                      checked={false}
                      onChange={() => {}}
                    />
                  )}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.9 }}
                  className='flex flex-col items-center justify-center gap-y-1'
                > 
                <span className='text-sm'>Listing</span>
                  {collection.Listing ? (
                    <Switch
                      className={`${collection.Listing ? 'bg-main' : 'bg-basic'}`}
                      checked={true}
                      onChange={() => {}}
                    />
                  ) : (
                    <Switch
                      className={`${collection.Listing ? 'bg-main' : 'bg-basic'}`}
                      checked={false}
                      onChange={() => {}}
                    />
                  )}
                </motion.div>
              </div>
            </div>
          </Card>
        ))
      )}
      {/* Staking setup dialog */}
      <Dialog
        open={close}
        handler={handleClose}
        className="flex flex-col  text-white bg-bgAdmin overflow-y-scroll overflow-hidden"
        size="lg"
        animate={{
          mount: { y: 0 },
          unmount: { y: -200 },
        }}
      >
        <div className=" rounded-lg">
          <EditHolderVerification
            loadCollection={holderList}
            selectedCard={selectedCard}
            updateLoadCollection={updateLoadCollection}
          />
        </div>
      </Dialog>
    </section>
  )
}

export default LoadHolderVerification
