import { Card } from '@material-tailwind/react'
import React from 'react'
import ComingSoon from '../../img/comming-soon.png'

const LoadVotingSystem = () => {
  return (
    <Card className='bg-second shadow-md shadow-shadow rounded-lg w-72 h-80 hover:rotate-[0.2deg] group transition-all duration-300'>
      <img src={ComingSoon} alt='Coming Soon' className='w-full h-full group-hover:bg-dark/10 transition-all duration-300 border rounded-lg border-basic hover:cursor-wait' />
    </Card>
  )
}

export default LoadVotingSystem