import { Button, Card, Collapse, Input } from '@material-tailwind/react'
import { motion } from 'framer-motion'
import React, { useState, useEffect, useRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { BiEdit, BiSearch } from 'react-icons/bi'
import coin from '../../img/coin.png'
import MultiselectRole from '../components/multiselectRole'
import ConditionsRewards from './_components/ConditionsRewards'
import TraitsAdd from './_components/TraitsAdd'

export const EditToken = ({ listStaking }) => {
  const [saveStaking, setSaveStaking] = useState(listStaking || [])
  const [tokenName, setTokenName] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [editToken, setEditToken] = useState(false)
  const toggleOpen = () => setOpen((cur) => !cur)
  const [open, setOpen] = useState(false)
  const [openTable, setOpenTable] = useState(false)
  const buttonTraitTable = () => setOpenTable((cur) => !cur)
  const [newTrait, setNewTrait] = useState([])
  const [listNewTrait, setListNewTrait] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [openInfo, setOpenInfo] = useState(false)
  const [channelList, setChannelList] = useState([])
  const [roleList, setRoleList] = useState([])
  const saveStakingRef = useRef(saveStaking)
  const [channelDetails, setChannelDetails] = useState([])
  const [roleDetails, setRoleDetails] = useState()
  const [newCondition, setNewCondition] = useState([])
  const [roleAbleToStake, setRoleAbleToStake] = useState([])

  const conditions = saveStaking.map((staking) => {
    return {
      MinimumWithdrawal: staking.WithdrawMinimumBalance,
      MinimumDays: staking.WithdrawMinimumDays,
      RewardPerDay: staking.TotalRewardPerDay,
    }
  })

  // Adiciona o novo trait à coleção atual
  useEffect(() => {
    if (
      newTrait.length > 0 ||
      tokenName ||
      tokenAddress ||
      Object.keys(newCondition).length > 0
    ) {
      const updatedListStaking = saveStaking.map((staking) => {
        return {
          ...staking,
          TokenName: tokenName || staking.TokenName,
          TokenAddress: tokenAddress || staking.TokenAddress,
          WithdrawMinimumDays:
            newCondition.MinimumDays !== undefined
              ? newCondition.MinimumDays
              : staking.WithdrawMinimumDays,
          WithdrawMinimumBalance:
            newCondition.MinimumWithdrawal !== undefined
              ? newCondition.MinimumWithdrawal
              : staking.WithdrawMinimumBalance,
          TotalRewardPerDay:
            newCondition.RewardPerDay !== undefined
              ? newCondition.RewardPerDay
              : staking.TotalRewardPerDay,
          StakedCollection: staking.StakedCollection.map((collection) => {
            const updatedTraitRewards = [...collection.TraitRewards]
            newTrait.forEach((newTraitItem) => {
              const exists = updatedTraitRewards.some(
                (trait) =>
                  trait.TraitType === newTraitItem.TraitType &&
                  trait.TraitValue === newTraitItem.TraitValue
              )
              if (!exists) {
                updatedTraitRewards.push(newTraitItem)
              }
            })
            return {
              ...collection,
              TraitRewards: updatedTraitRewards,
            }
          }),
        }
      })
      setSaveStaking(updatedListStaking)
      setNewTrait([])
    }
  }, [newTrait, tokenName, tokenAddress, newCondition])

  const handleDeleteTrait = (traitToDelete) => {
    setSaveStaking((prevStaking) => {
      if (!prevStaking) return []
      return prevStaking.map((staking) => {
        return {
          ...staking,
          StakedCollection: staking.StakedCollection.map((collection) => {
            return {
              ...collection,
              TraitRewards: collection.TraitRewards.filter(
                (trait) =>
                  trait.TraitType !== traitToDelete.TraitType ||
                  trait.TraitValue !== traitToDelete.TraitValue
              ),
            }
          }),
        }
      })
    })
  }

  useEffect(() => {
    saveStakingRef.current = saveStaking
  }, [saveStaking])

  useEffect(() => {
    const serverIds = saveStakingRef.current
      .map((staking) => staking.GuildId)
      .join(',')
    const fetchChannels = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/channels/${serverIds}`
        )
        const data = await response.json()
        setChannelList(data)
      } catch (error) {
        console.error('Error fetching server list:', error)
      }
    }
    fetchChannels()

    const fetchRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/roles/${serverIds}`
        )
        const data = await response.json()
        setRoleList(data)
      } catch (error) {
        console.error('Error fetching server list:', error)
      }
    }
    fetchRoles()
  }, [])

  useEffect(() => {
    if (channelList.length > 0) {
      const details = saveStaking.map((staking) => {
        const channel = channelList.find(
          (channel) => channel.id === staking.StakingChannelId
        )
        return channel || null
      })
      setChannelDetails(details)
    }
  }, [channelList, saveStaking])

  useEffect(() => {
    if (roleList.length > 0) {
      const details = saveStaking.flatMap((staking) => {
        return staking.RoleAbleToStake.map((roleId) => {
          const role = roleList.find((role) => role.id === roleId)
          return role || null
        })
      })
      setRoleDetails(details)
    }
  }, [roleList, saveStaking])

  const updateStaking = async () => {
    const updatedStaking = saveStaking.map((staking) => {
      return {
        guildId: staking.GuildId,
        tokenName: staking.TokenName,
        tokenAddress: staking.TokenAddress,
        dailyRewardAmount: staking.TotalRewardPerDay,
        minimumWithdrawalBalance: staking.WithdrawMinimumBalance,
        minimumWithdrawalDays: staking.WithdrawMinimumDays,
        stakingCollectionAddress:
          staking.StakedCollection[0]?.CollectionAddress || '',
        stakingChannelId: staking.StakingChannelId,
        eligibleRole: roleAbleToStake.map((role) => role.value),
        includeTraitSpecificRewards: true,
        traitRewardList: staking.StakedCollection.flatMap((collection) =>
          collection.TraitRewards.map((trait) => ({
            type: trait.TraitType,
            value: trait.TraitValue,
            reward: trait.TraitReward,
          }))
        ),
        includeRewardDetails: true,
      }
    })

    const url = `${process.env.REACT_APP_API_BASE_URL}/staking/edit`
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedStaking[0]),
      })
      if (!response.ok) {
        throw new Error('Failed to update staking')
      }
      const data = await response.json()
    } catch (error) {
      console.error('Error updating staking:', error)
    }
  }

  return (
    <section className="w-full h-[90vh] text-white flex flex-col bg-second">
      <div>
        <div className="flex top-0 left-0 h-10 bg-third w-full "></div>
        <div className="h-5 w-2/3 bg-third rounded-br-full shadow-2xl shadow-shadow"></div>
      </div>
      <div className="w-full h-full flex flex-col items-center justify-center rounded-lg ">
        {saveStaking.map((staking, stakingIndex) => (
          <div
            key={stakingIndex}
            className="w-full h-full flex flex-col gap-y-5"
          >
            {staking.StakedCollection.map((collection, collectionIndex) => (
              <div
                key={collectionIndex}
                className="w-full h-full flex flex-row"
              >
                {/* Token direita */}
                <div className="flex flex-col w-3/5 h-full rounded-lg ">
                  <div className="h-full flex flex-col p-5 gap-y-5 ">
                    {/* Token info */}
                    <div className="w-full h-[142px] flex flex-row gap-x-5 items-start">
                      <img
                        src={coin}
                        alt="FakeCoin"
                        className="w-20 h-20 animate-spinslow "
                      />
                      <div className="w-auto flex flex-col gap-y-2">
                        <motion.span
                          initial={{ opacity: 0, x: -50 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.5 }}
                          className="flex justify-between items-center text-4xl font-bold "
                        >
                          {staking.TokenName}
                          <Button
                            className="flex justify-center bg-basic p-2 items-center"
                            onClick={() => {
                              toggleOpen()
                              setTokenName(staking.TokenName)
                              setTokenAddress(staking.TokenAddress)
                            }}
                          >
                            <BiEdit className="text-xl" />
                          </Button>
                        </motion.span>
                        <motion.span
                          initial={{ opacity: 0, x: -50 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.5 }}
                          className="text-xs w-full pr-2"
                        >
                          {staking.TokenAddress}
                        </motion.span>
                      </div>
                      <Collapse
                        className="z-50"
                        animate={{
                          initial: { x: 300 },
                          mount: { x: 0 },
                          unmount: { x: -50 },
                        }}
                        open={open}
                      >
                        <Card className="flex flex-col gap-y-5 p-4 bg-basic">
                          <Input
                            label="Enter the name of token*"
                            placeholder="Exemple: Bonk, King, Dust, etc."
                            variant="static"
                            color="deep-purple"
                            className="focus:outline-none active:border-spacing-1 focus:bg-basic w-full text-white px-2"
                            labelProps={{
                              className:
                                'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
                            }}
                            value={tokenName}
                            onChange={(e) => {
                              setTokenName(e.target.value)
                            }}
                          />
                          <Input
                            label="Enter the token address here*"
                            placeholder="Example: DezXAZ8z7PnrnRjz3wXBoRgixCa6xjnB7"
                            variant="static"
                            color="deep-purple"
                            className="focus:outline-none active:border-spacing-1 focus:bg-basic w-full text-white px-2"
                            labelProps={{
                              className:
                                'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
                            }}
                            value={tokenAddress}
                            onChange={(e) => {
                              setTokenAddress(e.target.value)
                            }}
                          />
                        </Card>
                      </Collapse>
                    </div>

                    {/* Token Condition */}
                    <div className="w-full h-[50%] flex flex-row group-hover:gap-x-1 group">
                      <div className="flex flex-col w-full bg-third rounded-xl  border-second shadow-xl">
                        <div className="w-full flex items-end justify-end pt-2 px-2">
                          <Button
                            className=" flex justify-center bg-basic p-2 items-center cursor-pointer"
                            onClick={() => {
                              setOpenInfo(!openInfo)
                            }}
                          >
                            <BiEdit className="text-xl" />
                          </Button>
                        </div>
                        {!openInfo ? (
                          <motion.div
                            initial={{ opacity: 0, y: 50, rotateY: 0 }}
                            animate={{
                              opacity: [0, 30, 100],
                              y: 0,
                              duration: 0.5,
                              rotateY: [-180, 0, 0],
                            }}
                            className="flex flex-row w-full h-full mb-[5%]"
                          >
                            <motion.div
                              initial={{ opacity: 0, x: -50 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.5 }}
                              className="h-full w-1/2 flex flex-col border-r-4 mb-[5%] border-second"
                            >
                              <div className="w-full h-full flex flex-row gap-x-12 justify-center items-center -mt-5 ">
                                {/* Minimum coins withdrawal */}
                                <div className="flex flex-col">
                                  <div className="flex flex-row items-end justify-center">
                                    <span className="text-2xl font-bold">
                                      {staking.WithdrawMinimumBalance}
                                    </span>
                                    <span className="text-xs pb-1">Tokens</span>
                                  </div>
                                  <div className="flex flex-col items-center justify-center">
                                    <span className="font-bold text-sm flex">
                                      Minimum
                                    </span>
                                    <span className="font-bold text-sm flex -pt-1">
                                      withdrawal
                                    </span>
                                  </div>
                                </div>
                                {/* Minimum days withdrawal */}
                                <div className="flex flex-col">
                                  <div className="flex flex-row items-end justify-center">
                                    <span className="text-2xl font-bold">
                                      {staking.WithdrawMinimumDays}
                                    </span>
                                    <span className="text-xs font-bold pb-1">
                                      Days
                                    </span>
                                  </div>
                                  <div className="flex flex-col items-center justify-center">
                                    <span className="font-bold text-sm flex">
                                      Withdrawal
                                    </span>
                                    <span className="font-bold text-sm flex -pt-1">
                                      after
                                    </span>
                                  </div>
                                </div>
                                {/* Total reward per day */}
                                <div
                                  className=" flex flex-col items-center justify-center"
                                  initial={{ opacity: 0, x: 100 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <div className="flex flex-row items-end justify-center gap-x-0.5">
                                    <span className="text-2xl font-bold">
                                      {staking.TotalRewardPerDay}
                                    </span>
                                    <span className="text-sm pb-1">tokens</span>
                                  </div>
                                  <span className="text-sm font-bold text-center flex">
                                    Reward
                                  </span>
                                  <span className="text-sm font-bold text-center flex -mt-0.5">
                                    per day
                                  </span>
                                </div>
                              </div>
                              <div className="w-full h-full flex flex-row gap-x-12 justify-center items-center -mt-20 ">
                                <div className="w-full flex flex-col items-center justify-center ">
                                  <div
                                    initial={{ opacity: 0, x: 100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.7 }}
                                    className="w-full flex flex-col items-center"
                                  >
                                    <span className="text-2xl font-bold">
                                      {collection.TraitRewards.reduce(
                                        (acc, trait) => acc + trait.TraitReward,
                                        0
                                      )}
                                    </span>
                                    <span className="text-sm font-bold ">
                                      Traits
                                    </span>
                                    <span className="text-sm font-bold -mt-0.5">
                                      for reward
                                    </span>
                                  </div>
                                </div>
                                <div className="w-full flex flex-col items-center justify-center ">
                                  <div
                                    initial={{ opacity: 0, x: 100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.9 }}
                                    className="w-full flex flex-col items-center text-center"
                                  >
                                    <span className="text-2xl font-bold">
                                      {collection.TraitRewards.length}
                                    </span>
                                    <span className="text-sm font-bold">
                                      Different
                                    </span>
                                    <span className="text-sm font-bold -mt-0.5">
                                      types
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                            <motion.div
                              initial={{ opacity: 0, x: -50 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.5 }}
                              className="flex flex-col w-1/2 h-full items-center justify-center "
                            >
                              <div className="flex flex-col gap-y-3 ">
                                <div className="flex flex-col items-center justify-center bg-basic px-3 py-2 rounded-md shadow-sm shadow-dark">
                                  <span className="text-sm font-semibold border-b w-full border-third">
                                    Server on in &nbsp;
                                  </span>
                                  <div className="flex flex-wrap gap-2 p-2 capitalize">
                                    <span className="bg-second hover:bg-third text-white px-3 py-2 rounded-md text-base shadow-sm shadow-dark hover:shadow-md transition-all duration-500">
                                      {channelList.map((channel) =>
                                        channel.id === staking.StakingChannelId
                                          ? channel.name
                                          : null
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className="flex flex-col items-center justify-center bg-basic px-3 py-2 rounded-md shadow-sm shadow-dark">
                                  <span className="text-sm font-semibold border-b w-full border-third">
                                    Role active in
                                  </span>
                                  <div className="flex flex-wrap gap-2 p-2 capitalize">
                                    {staking.RoleAbleToStake.map((roleId) => {
                                      const role = roleList.find(
                                        (role) => role.id === roleId
                                      )
                                      return role ? (
                                        <span
                                          key={roleId}
                                          className="bg-second hover:bg-third text-white px-3 py-2 rounded-md text-base  overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-sm shadow-dark hover:shadow-md transition-all duration-500"
                                        >
                                          {role.name}
                                        </span>
                                      ) : null
                                    })}
                                  </div>
                                </div>

                                {staking.TraitBased === false ? (
                                  <span className="text-xs font-bold">
                                    No specific rewards staked
                                  </span>
                                ) : (
                                  <div className="flex flex-col items-center justify-center bg-basic px-3 py-2 rounded-md shadow-sm shadow-dark">
                                    <span className="text-sm font-semibold border-b w-full border-third">
                                      Specific rewards staked from the
                                      collections
                                    </span>
                                    <div className="flex flex-wrap gap-2 p-2 capitalize">
                                      {staking.StakedCollection.map(
                                        (collection, index) => (
                                          <span
                                            key={index}
                                            className="bg-second hover:bg-third text-white px-3 py-2 rounded-md text-base  overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-sm shadow-dark hover:shadow-md transition-all duration-500"
                                          >
                                            {collection.CollectionName}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </motion.div>
                          </motion.div>
                        ) : (
                          <motion.div
                            className="h-full w-full flex flex-col items-center justify-center  "
                            initial={{ opacity: 0, y: 50, rotateY: 0 }}
                            animate={{
                              duration: 0.5,
                              opacity: [0, 30, 100],
                              y: 0,
                              rotateY: [180, 0, 0], // Girar para frente e voltar
                            }}
                          >
                            <ConditionsRewards
                              setConditions={conditions}
                              newCondition={setNewCondition}
                            />
                          </motion.div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Token esquerda */}
                <div
                  div
                  className="w-[45%] h-[80%] mb-28 flex flex-col p-5 rounded-br-lg items-center justify-center "
                >
                  <div className="w-full h-full flex flex-col gap-y-2 justify-start bg-third rounded-lg shadow-sm shadow-shadow p-3">
                    <div className="flex flex-col items-end justify-between rounded-lg">
                      <div className="flex w-full items-end justify-end">
                        <Button
                          onClick={() => {
                            buttonTraitTable()
                          }}
                          className="flex justify-center bg-basic p-2 items-center h-10 w-10"
                        >
                          <BiEdit className="text-xl" />
                        </Button>
                      </div>
                      {openTable && (
                        <div className="flex w-full py-3 items-start justify-start ">
                          <TraitsAdd
                            CollectionAddress={collection.CollectionAddress}
                            newTrait={setNewTrait}
                          />
                        </div>
                      )}
                    </div>
                    <Card
                      className={`bg-basic overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow ${openTable ? '' : 'h-full'}`}
                    >
                      <Input
                        placeholder="Filtrar por tipo ou valor"
                        variant="static"
                        value={searchQuery}
                        onChange={(e) =>
                          setSearchQuery(e.target.value.toLowerCase())
                        }
                        className="w-full bg-second border-none px-5"
                        icon={<BiSearch className="text-xl" />}
                      />
                      <table className="w-full text-left">
                        <thead className="text-xs bg-second uppercase">
                          <tr>
                            <th
                              scope="col"
                              className="text-center border-r border-third py-2"
                            >
                              Trait
                            </th>
                            <th
                              scope="col"
                              className="text-center py-2 border-r border-third"
                            >
                              Value
                            </th>
                            <th scope="col" className="py-2 text-center">
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {collection.TraitRewards.filter((trait) => {
                            return (
                              (trait.TraitType?.toLowerCase?.() || '').includes(
                                searchQuery
                              ) ||
                              (
                                trait.TraitValue?.toLowerCase?.() || ''
                              ).includes(searchQuery)
                            )
                          })
                            .reverse()
                            .map(
                              (
                                trait,
                                index // Adiciona o reverse() aqui
                              ) => (
                                <tr
                                  className="bg-basic hover:bg-third border-second border-t"
                                  key={index}
                                >
                                  <td className="p-2 border-second text-center flex gap-x-3">
                                    <button
                                      onClick={() => handleDeleteTrait(trait)}
                                      className={
                                        openTable
                                          ? 'flex justify-center bg-transparent p-0.5 items-center'
                                          : 'pl-5 hidden'
                                      }
                                    >
                                      <AiOutlineClose className="text-xl font-bold text-red-500" />
                                    </button>
                                    {trait.TraitType}
                                  </td>
                                  <td className="p-2 border-second pl-5 text-left">
                                    {trait.TraitValue}
                                  </td>
                                  <td className="p-2 border-second text-center">
                                    {trait.TraitReward}
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </Card>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className="absolute flex flex-col bottom-0 left-0 justify-end items-end w-full">
          <div className="bg-third w-2/3 h-6 rounded-tl-xl shadow shadow-shadow"></div>
          <div className="bg-third w-full h-11 "></div>
          <Button
            onClick={updateStaking}
            className="absolute bottom-3 right-3 bg-mainHover p-2 text-xl"
          >
            Update
          </Button>
        </div>
      </div>
    </section>
  )
}
