import React, { useState } from 'react'
import CreateHolderVerification from './CreateHolderVerification'
import LoadHolderVerification from './LoadHolderVerification'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Typography,
  MenuItem,
} from "@material-tailwind/react";

const HolderVerification = () => {
  const [newCollection, setNewCollection] = useState(false)
  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [openNewCollection, setOpenNewCollection] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)


  const updateLoadCollection = () => {
    setUpdateTrigger(!updateTrigger)
  }

  //Dialog create new collection
  const handleDialogClose = () => {
    setConfirmClose(true)
  }
  const handleConfirmClose = (confirm) => {
    if (confirm) {
      setOpenNewCollection(false)
    }
    setConfirmClose(false)
  }

  return (
    <section className="container mx-auto h-full text-white py-5">
      <h1 className="text-4xl pb-14 font-bold w-full flex justify-between">
        Holder Verification
        {!newCollection && (
          <Button
            onClick={() => setOpenNewCollection((cur) => !cur)}
            color="deep-purple"
            className="h-full font-bold tracking-widest "
          >
            New Collection
          </Button>
        )}
      </h1>
      <LoadHolderVerification updateTrigger={updateTrigger} />
      {newCollection && (
        <CreateHolderVerification updateLoadCollection={updateLoadCollection} />
      )}
      <Dialog
        className="flex flex-col text-white bg-bgAdmin bg-no-repeat bg-opacity-25 bg-cover bg-right-bottom overflow-hidden h-[90vh] shadow-shadow border border-dark overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic"
        size='md'
        animate={{
          mount: { y: 0 },
          unmount: { y: -200 },
        }} open={openNewCollection} handler={handleDialogClose}>
        <CreateHolderVerification />
      </Dialog>
      <Dialog className="flex flex-col  text-white bg-basic  rounded-lg"
        size="xs"
        animate={{
          mount: { y: 0 },
          unmount: { y: -200 },
        }} 
        open={confirmClose} handler={() => setConfirmClose(false)}>
        <div className="p-4 border border-third rounded-lg shadow-shadow">
          <h2 className="text-lg font-bold">Do you want to cancel creating a new collection?</h2>
          <div className="flex justify-end items-end gap-2 mt-4">
            <Button color="red" size='sm' variant='text' onClick={() => handleConfirmClose(false)}>
              Cancel
            </Button>
            <Button className='bg-main hover:bg-mainHover' color='deep-purple' size='sm' onClick={() => handleConfirmClose(true)}>
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </section>
  )
}

export default HolderVerification
