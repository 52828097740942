import React, { useState, useEffect } from 'react'
import DecentralizedGallery, {
  useFetchImages,
} from './_components/DecentralizedGallery'
import DecentralizedUpload from './_components/DecentralizedUpload'

const DecentralizedStorage = () => {
  const { fetchImages, imageGallery, isLoading, error } = useFetchImages()
  const [images, setImages] = useState([])

  useEffect(() => {
    setImages(imageGallery)
  }, [imageGallery])

  return (
    <>
      <div className="flex flex-col container mx-auto w-full h-full py-5 text-white gap-y-5">
        <DecentralizedUpload fetchImages={fetchImages} />
        <DecentralizedGallery
          images={images}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </>
  )
}

export default DecentralizedStorage
