import { Button, Input } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { FaCloudUploadAlt, FaTimes } from 'react-icons/fa'
import InputTokenDecimals from './_components/InputTokenDecimals'
import InputTokenTotal from './_components/InputTokenTotal'

const SectionTokenCreation = ({ onTokenInfoChange }) => {
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  const [tokenDecimals, setTokenDecimals] = useState('0')
  const [tokenTotal, setTokenTotal] = useState('0')
  const [uploadedImage, setUploadedImage] = useState(null)
  const [tokenImage, setTokenImage] = useState(null)

  const tokenInfo = {
    name: tokenName,
    symbol: tokenSymbol,
    description: tokenDescription,
    image: uploadedImage,
    decimals: tokenDecimals,
    total: tokenTotal,
    guildId: localStorage.getItem('selectedServer'),
  }

  useEffect(() => {
    onTokenInfoChange(tokenInfo)
  }, [
    tokenName,
    tokenSymbol,
    tokenDescription,
    uploadedImage,
    tokenDecimals,
    tokenTotal,
  ])

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setUploadedImage(e.target.result)
        setTokenImage(file)
      }
      reader.readAsDataURL(file)
    }
  }
  const handleDeleteImage = () => {
    setUploadedImage(null)
  }

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-row gap-4 w-full">
        <div className="flex items-center">
          {!uploadedImage ? (
            <Button
              color="deep-purple"
              fullWidth={true}
              className="w-60 h-60 flex flex-col gap-y-5 uppercase items-center justify-center text-center hover:shadow-lg hover:bg-accentMain"
              onClick={() => document.getElementById('fileInput').click()}
            >
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
              <FaCloudUploadAlt className="text-5xl" />
              Upload your token image
            </Button>
          ) : (
            <div className="w-60 h-60 relative">
              <button
                onClick={handleDeleteImage}
                className=" w-full h-full shadow-sm text-transparent hover:text-white hover:bg-black hover:saturate-200 hover:backdrop-blur-sm"
              >
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  className="w-full h-full object-cover"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex rounded-xl text-center items-center justify-center text-xs font-bold  p-4">
                  Choose image
                </span>
                <span className="absolute w-full h-full text-red-50 hover:text-transparent top-0 left-0 ">
                  <FaTimes />
                </span>
              </button>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full justify-center gap-y-5">
          <div className="flex flex-row gap-x-5">
            <div className="w-2/3 bg-third rounded-lg">
              <Input
                label="Enter the name of your token *"
                placeholder="Valid options: Bonk, King, Dust, etc."
                variant="outlined"
                color="deep-purple"
                size="lg"
                className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
                }}
                value={tokenName}
                onChange={(e) => setTokenName(e.target.value)}
              />
            </div>
            <div className="w-1/3 bg-third rounded-lg">
              <Input
                label="Enter the symbol of your token *"
                placeholder="Valid options: BNK, KNG, DST, etc."
                variant="outlined"
                color="deep-purple"
                size="lg"
                className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic w-full text-white "
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
                }}
                value={tokenSymbol}
                onChange={(e) => setTokenSymbol(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full bg-third rounded-lg">
            <Input
              label="Enter the description name of your token *"
              placeholder="This is best utility token ever created"
              variant="outlined"
              color="deep-purple"
              size="lg"
              className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
              labelProps={{
                className:
                  'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
              }}
              value={tokenDescription}
              onChange={(e) => setTokenDescription(e.target.value)}
            />
          </div>
          <div className="flex w-full justify-end gap-x-10 px-3">
            <InputTokenDecimals
              value={tokenDecimals}
              onChange={setTokenDecimals}
            />
            <InputTokenTotal value={tokenTotal} onChange={setTokenTotal} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionTokenCreation
