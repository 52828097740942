import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const optionsRole = [
  { label: 'Immediate', value: 0 },
  { label: 'After 7 days', value: 7 },
  { label: 'After 30 days', value: 30 },
]

const animatedComponents = makeAnimated()
const DropdownAmountDay = ({ onAmountDayChange }) => {
  const [selectedAmountDay, setSelectedAmountDay] = useState('')
  const handleAmountDayChange = (selectedOption) => {
    setSelectedAmountDay(selectedOption)
    onAmountDayChange(selectedOption.value)
  }

  return (
    <div className="flex flex-col w-1/2 gap-y-1">
      <span className="px-3 text-base font-thin">Minimum amount of days*</span>
      <Select
        placeholder="Select a minimum amount of days"
        components={animatedComponents}
        options={optionsRole}
        value={selectedAmountDay}
        onChange={handleAmountDayChange}
        styles={{
          valueContainer: (provided, state) => ({
            ...provided,
            padding: state.hasValue ? '0.5rem 0' : '0.5rem',
          }),
          container: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            border: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
          }),
          control: (provided, state) => ({
            ...provided,
            height: 48,
            maxWidth: '100%',
            backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
            color: '#fff',
            borderRadius: 8,
            borderColor: state.isFocused ? '#9945ff' : '#fff',
            boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
            paddingLeft: '1rem',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#9945ff'
              : state.isFocused
                ? '#9945ff'
                : '#393D5E',
            color: '#fff',
            cursor: 'pointer',
            padding: 0,
            paddingLeft: '1rem',
            margin: 0,
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#9945ff',
            color: '#fff',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
          }),
        }}
      />
    </div>
  )
}

export default DropdownAmountDay
