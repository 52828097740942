import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { Button, Input, Option, Select, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { MdOutlineAdd, MdOutlineKeyboardArrowDown, MdOutlineRemove, MdUpload } from 'react-icons/md'

const NftSetup = ({
  onRoleSelect,
  onQuantitySelect,
  onTokenAddress,
  onCollectionName,
  onMasterAddress,
  onHashlist,
  onHashlistFile,
  onSkipRole,
}) => {
  const [value, setValue] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState([null])
  const [tokenAddress, setTokenAddress] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [masterAddress, setMasterAddress] = useState('')
  const [isMasterAddress, setIsMasterAddress] = useState(false)
  const [isHashlist, setIsHashlist] = useState(false)
  const [isSkipRole, setIsSkipRole] = useState(false)
  const [hashlistFile, setHashlistFile] = useState(null)


  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [])

  const increment = () => setValue(value + 1)
  const decrement = () => {
    if (value <= 1) {
      setValue(1)
    } else {
      setValue(value - 1)
    }
  }

  useEffect(() => {
    onQuantitySelect(value)
  }, [value, onQuantitySelect])

  useEffect(() => {
    onRoleSelect(selectedRole)
  }, [selectedRole, onRoleSelect])

  useEffect(() => {
    onCollectionName(collectionName)
  }, [collectionName, onCollectionName])

  useEffect(() => {
    onTokenAddress(tokenAddress)
  }, [tokenAddress, onTokenAddress])

  useEffect(() => {
    onMasterAddress(masterAddress)
  }, [masterAddress, onMasterAddress])

  useEffect(() => {
    onHashlist(isHashlist)
  }, [isHashlist, onHashlist])

  useEffect(() => {
    onSkipRole(isSkipRole)
  }, [isSkipRole, onSkipRole])

  useEffect(() => {
    onHashlistFile(hashlistFile)
  }, [hashlistFile, onHashlistFile])

  const handleHashlistUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setHashlistFile(file)
      // Process the file as needed
    }
  }

  return (
    <>
      <section className="flex flex-col gap-y-5 py-10">
        {/* section 1 */}
        <div className="flex flex-col gap-y-5 ">
          {/* Collection Name */}
          <div className='w-full flex flex-col justify-end'>
            <span className='text-sm font-semibold'>Enter a name of Collection*</span>
            <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
              <Input
                placeholder="Exemple: My Collection."
                color="deep-purple"
                variant="static"
                className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none "
                textColor="white"
                size="lg"
                containerProps={{
                  className: 'bg-second ',
                }}
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                  style: {
                    color: 'white'
                  }
                }}
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
            </div>
          </div>
          {/* Input Address */}
          <div className='flex flex-col w-full '>
            <div className='w-full flex flex-col justify-end'>
              <span className='text-sm font-semibold'>Enter a token address*</span>
              <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                <Input
                  placeholder="Exemple: 0x1234567890123456789012345678901234567890"
                  color="deep-purple"
                  variant="static"
                  className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                  textColor="white"
                  size="lg"
                  containerProps={{
                    className: 'bg-second ',
                  }}
                  labelProps={{
                    className:
                      'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                    style: {
                      color: 'white'
                    }
                  }}
                  value={tokenAddress}
                  onChange={(e) => setTokenAddress(e.target.value)}
                />
              </div>
            </div>
            <div className=" flex-col gap-y-5 flex pt-2">
              <div className="flex justify-between">
                <span className="font-semibold">Do you have a master address?</span>
                <Switch
                  className={`${isMasterAddress ? 'bg-mainHover' : ''}`}
                  label={`${isMasterAddress ? 'Yes' : 'No'}`}
                  checked={isMasterAddress}
                  onChange={() => setIsMasterAddress(!isMasterAddress)}
                />
              </div>
              {isMasterAddress && (
                <div className='w-full flex flex-col justify-end'>
                  <span className='text-sm font-semibold'>Enter a master address*</span>
                  <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                    <Input
                      placeholder="Exemple: 0x1234567890123456789012345678901234567890"
                      color="deep-purple"
                      variant="static"
                      className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                      textColor="white"
                      size="lg"
                      containerProps={{
                        className: 'bg-second ',
                      }}
                      labelProps={{
                        className:
                          'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                        style: {
                          color: 'white'
                        }
                      }}
                      value={masterAddress}
                      onChange={(e) => setMasterAddress(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* section 2 */}
        <hr className="my-3 border-third" />
        <div className='flex flex-col gap-y-5'>
          {/* Hashlist */}
          <div className="flex flex-col gap-y-2">
            <div className='flex w-full justify-between'>
              <span className="font-semibold">Do you want to upload a hashlist?</span>
              <Switch
                className={`${isHashlist ? 'bg-mainHover' : ''}`}
                label={`${isHashlist ? 'Yes' : 'No'}`}
                checked={isHashlist}
                onChange={() => setIsHashlist(!isHashlist)}
              />
            </div>
            {isHashlist && (
              <div className="w-full flex justify-end">
                <div div className={`flex items-end justify-end border border-main p-2 rounded-lg w-52 group ${hashlistFile ? 'hover:bg-main hover:border hover:border-third' : ''}`}>
                  <label htmlFor="hashlist-upload" className={`items-center justify-center cursor-pointer w-full flex gap-x-2 `}>
                    {hashlistFile ? (
                      <div className="flex items-center justify-center text-sm font-bold w-full">
                        <span className={hashlistFile ? "group-hover:hidden" : "hidden"}>{hashlistFile ? hashlistFile.name : "Upload List"}</span>
                        <span className={hashlistFile ? "hidden group-hover:flex items-center justify-center text-white uppercase" : "group-hover:hidden"}>change file</span>
                      </div>
                    ) : (
                      <div className="text-white text-sm font-bold uppercase flex flex-row gap-x-2 items-center">
                        Upload List <BsFillCloudArrowUpFill size={24} />
                      </div>
                    )}
                  </label>
                  <input
                    id="hashlist-upload"
                    type="file"
                    accept=".csv, .xml, .json"
                    className="hidden"
                    onChange={handleHashlistUpload}
                  />
                </div>
              </div>
            )}
          </div>
          {/* Skip Role */}
          <div className='flex w-full justify-between items-center'>
            <span className=" font-semibold">Do you want to skip role?</span>
            <Switch
              className={`${isSkipRole ? 'bg-mainHover' : ''}`}
              label={`${isSkipRole ? 'Yes' : 'No'}`}
              checked={isSkipRole}
              onChange={() => setIsSkipRole(!isSkipRole)}
            />
          </div>
        </div>
        {/* section 3 */}
        <hr className="my-3 border-third" />
        <div className="flex gap-x-5 justify-end">
          {/* Select Role */}
          <div className='w-full flex flex-col justify-end'>
            <span className='text-sm'>Select a Role</span>
            <div className="w-full flex flex-col bg-second rounded-lg pl-3 border border-third">
              <Select
                variant="standard"
                color="deep-purple"
                className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                textColor="white"
                size="lg"
                containerProps={{
                  className: 'bg-third ',
                }}
                labelProps={{
                  className:
                    'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                }}
                menuProps={{
                  className: 'text-white bg-third border-none font-semibold',
                }}

                onChange={(value) => {
                  const selected = roles.find((role) => role.id === value)
                  setSelectedRole(selected)
                }}

              >
                {roles.map((role) => (
                  <Option
                    className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                    key={role.id}
                    value={role.id}
                  >
                    {role.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default NftSetup
