import React, { useEffect, useState } from 'react'
import AvatarImage from '../../img/TektoolsFaceWhite.png'

const ChooseServer = () => {
  const [serverBot, setServerBot] = useState([])
  const [serverNotBot, setServerNotBot] = useState([])
  const [isFetched, setIsFetched] = useState(false)


  const fetchRoles = async (server) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/roles/${server.id}`
      )
      const data = await response.json()
      if (data.statusCode === 404) {
        setServerNotBot(prev => [...prev, server])
        return false
      }
      return true
    } catch (error) {
      console.error(error)
      setServerNotBot(prev => [...prev, server])
      return false
    }
  }

  const fetchServers = () => {
    if (isFetched) return

    const userData = JSON.parse(localStorage.getItem('userData'))
    const accessToken = userData.tokenInfo.accessToken
    fetch(`${process.env.REACT_APP_API_BASE_URL}/guilds`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        accessToken: accessToken,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        const validServers = []
        for (const server of data) {
          const isValid = await fetchRoles(server)
          if (isValid) {
            validServers.push(server)
          }
        }
        setServerBot(validServers)
        setIsFetched(true)
      })
      .catch((error) => {
        console.error('Error fetching server list:', error)
      })
  }

  useEffect(() => {
    fetchServers()
  }, [])


  const GoServerChange = (serverId) => {
    localStorage.setItem('selectedServer', serverId)
    window.location.href = '/dashboard'
  }

  const InstallBot = (serverId) => {
    window.location.href = '/dashboard/addtektools'
    console.log(`Instalar BOT no servidor ${serverId}`)
  }

  return (
    <section className="flex h-screen w-full text-white py-10 overflow-y-auto mx-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
      <div className="mx-auto">
        <div className="container">
          <h1 className="font-medium text-4xl pb-10 flex">Select a Server</h1>
          <div className="flex flex-wrap w-full justify-center gap-8 py-5 ">
            {serverBot.map((server, index) => (
              <div
                key={index}
                className="flex w-80 h-72 bg-second rounded-xl shadow-lg drop-shadow-lg shadow-shadow hover:bg-second/80"
              >
                <div className="flex flex-col bg-gradient-to-b from-main from-15% via-transparent to-transparent w-full mx-8 my-4 rounded-xl items-center p-2 py-5">
                  <div className="w-28 h-28 mx-auto">
                    {server.icon === null ? (
                      <img
                        src={AvatarImage}
                        alt="Avatar"
                        className="w-full h-full object-cover rounded-full bg-blend-color-dodge "
                      />
                    ) : (
                      <img
                        src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                        alt={server.name}
                        className="w-full h-full object-cover rounded-full rol"
                      />
                    )}
                  </div>

                  <div className="text-center py-3">
                    <h2 className="font-semibold text-2xl truncate w-full">{server.name}</h2>
                    <p>{server.owner === true ? 'Owner' : 'Admin'}</p>
                  </div>
                  <div className="flex justify-center w-full">
                    <button
                      type="button"
                      onClick={() => GoServerChange(server.id)}
                      className="uppercase bg-accentMain rounded-lg items-center justify-center w-full h-12 text-center text-base font-bold hover:bg-accentHouver tracking-wide"
                    >
                      Go
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {serverNotBot.map((server, index) => (
              <div
                key={index}
                className="flex w-80 h-72 bg-second rounded-xl shadow-lg drop-shadow-lg shadow-shadow hover:bg-second/80"
              >
                <div className="flex flex-col bg-gradient-to-b from-main from-15% via-transparent to-transparent w-full mx-8 my-4 rounded-xl items-center p-2 py-5">
                  <div className="w-28 h-28 mx-auto">
                    {server.icon === null ? (
                      <img
                        src={AvatarImage}
                        alt="Avatar"
                        className="w-full h-full object-cover rounded-full bg-blend-color-dodge "
                      />
                    ) : (
                      <img
                        src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                        alt={server.name}
                        className="w-full h-full object-cover rounded-full "
                      />
                    )}
                  </div>

                  <div className="text-center py-3">
                    <h2 className="font-semibold text-2xl truncate ">{server.name}</h2>
                    <p>{server.owner === true ? 'Owner' : 'Admin'}</p>
                  </div>
                  <div className="flex justify-center w-full">
                    <button
                      type="button"
                      onClick={() => InstallBot(server.id)}
                      className="uppercase bg-accentMain rounded-lg items-center justify-center w-full h-12 text-center text-base font-bold hover:bg-accentHouver tracking-wide"
                    >
                      Install BOT
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChooseServer
