import React, { useEffect, useState } from 'react'
import { AiFillDatabase, AiOutlineLogout, AiTwotoneUsb } from 'react-icons/ai'
import { BsArrowLeftRight } from 'react-icons/bs'
import { FiUserCheck } from 'react-icons/fi'
import {
  MdHowToVote,
  MdLockOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineToken,
} from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import DropdownChoose from '../ChooseServer/DropdownChoose'
import Tip from '../components/ui/tip'

const Sidebar = ({ setServerId }) => {
  const handleServerChange = (serverId) => {
    localStorage.setItem('selectedServer', serverId)
    setServerId(serverId)
  }

  return (
    <section className="h-full p-2 lg:p-6 text-white/70  drop-shadow-2xl shadow-2xl shadow-shadow">
      <div className="relative w-52">
        <div className="pb-8">
          <DropdownChoose onSelect={handleServerChange} />
        </div>
        <div className="flex flex-col gap-y-4">
          <NavLink
            to="AntiBotMembers"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <AiTwotoneUsb />
            Anti Bot Members
            <Tip
              tip="Consectetur voluptate veniam sunt magna mollit."
              placement="right-end"
            />
          </NavLink>
          <NavLink
            to="HolderVerification"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <FiUserCheck />
            Holder Verification
            <Tip
              tip="Nulla excepteur Lorem ex ea commodo fugiat eu amet nisi reprehenderit esse dolor."
              placement="right-end"
            />
          </NavLink>
          <NavLink
            to="Staking"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <BsArrowLeftRight />
            Staking Setup
          </NavLink>
          <NavLink
            to="TokenCreation"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <MdOutlineToken />
            SPL - Token Creation
          </NavLink>
          <NavLink
            to="RevenueSharing"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <AiOutlineLogout />
            Revenue Sharing
          </NavLink>
          <NavLink
            to="DecentralizeStorage"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <AiFillDatabase />
            Decentralized Store
          </NavLink>
          <NavLink
            to="VotingSystem"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <MdHowToVote />
            Voting System
          </NavLink>
          <NavLink
            to="erro"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <MdLockOutline />
            Password Lock
          </NavLink>
          <NavLink
            to="erro"
            className="flex items-center gap-2 py-1 px-2  hover:text-white"
          >
            <MdOutlineAccountBalanceWallet />
            Wallet Management
          </NavLink>
        </div>
      </div>
    </section>
  )
}

export default Sidebar
