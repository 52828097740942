import React from 'react'
import ChooseServer from './ChooseServer'

const ChooseMain = () => {
  return (
    <div className="h-screen w-full bg-bgAdmin bg-no-repeat bg-cover">
      <div className=""></div>
      <div className="h-auto">
        <ChooseServer />
      </div>
    </div>
  )
}

export default ChooseMain
