import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import makeAnimated from 'react-select/animated'

const DropdownChoose = ({ onSelect }) => {
  const userDataLocal = JSON.parse(localStorage.getItem('userData'))
  const [selectedServerId, setSelectedServerId] = useState(
    localStorage.getItem('selectedServer')
  )
  const [serverList, setServerList] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    const handleStorageChange = () => {
      setSelectedServerId(localStorage.getItem('selectedServer'))
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const handleServerChange = (selectedOption) => {
    const newSelectedServerId = selectedOption.value
    localStorage.setItem('selectedServer', newSelectedServerId)
    onSelect(newSelectedServerId)
    setSelectedServerId(newSelectedServerId)
  }

  useEffect(() => {
    const serverList = async () => {
      const accessToken = userDataLocal.tokenInfo.accessToken
      fetch(`${process.env.REACT_APP_API_BASE_URL}/guilds`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          accessToken: accessToken,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          const validServers = []
          for (const server of data) {
            const response = await fetch(
              `${process.env.REACT_APP_API_BASE_URL}/roles/${server.id}`
            )
            const roleData = await response.json()
            if (roleData.statusCode !== 404) {
              validServers.push(server)
            }
          }
          if (validServers.length > 0) {
            const filteredServers = validServers.map(server => ({
              id: server.id,
              name: server.name
            }))
            setServerList(filteredServers)
          } else {
            setServerList([{ id: 'none', name: 'No valid servers found' }])
          }
        })
        .catch((error) => {
          console.error('Error fetching server list:', error)
        })
    }
    serverList()
  }, [])

  const options = serverList.map((server) => ({
    value: server.id,
    label: server.name,
  }))

  const animatedComponents = makeAnimated()
  console.log(serverList)

  return (
    // <div className="relative">
    //   <select
    //     className=" w-full text-white/90 bg-second border border-white/70 hover:border-white px-4 py-3 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline appearance-none gap-y-3 flex flex-col"
    //     onChange={handleServerChange}
    //     value={selectedServerId || ''}
    //   >
    //     <option value="" hidden>
    //       Select Server
    //     </option>
    //     {Array.isArray(serverList) && serverList.map((server) => (
    //       <option  key={server.id} value={server.id}>
    //         {server.name}
    //       </option>
    //     ))}
    //     <hr className="my-3 border-third" />
    //     test
    //   </select>
    //   <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
    //     <RiArrowDropDownLine className="text-white text-2xl" />
    //   </div>
    // </div>
    <>
    <Select
        components={animatedComponents}
        isMulti={false}
        options={options}
        value={options.find(option => option.value === selectedServerId)}
        onChange={handleServerChange}
        
        styles={{
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#393D5E',
            color: '#fff',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: state.hasValue ? '0.5rem 0' : '0.5rem',
          }),
          container: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            border: 0,
          }),
          control: (provided, state) => ({
            ...provided,
            height: 48,
            maxWidth: '100%',
            backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
            color: '#fff',
            borderRadius: 8,
            borderColor: state.isFocused ? '#9945ff' : '#fff',
            boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
            paddingLeft: '1rem',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#393D5E'
              : state.isFocused
                ? '#9945ff'
                : '#9945ff',
            ":hover": {
              backgroundColor: '#393D5E',
            },
            text: {
              color: state.isSelected ? '#393D5E' : '#9945ff',
            },
            cursor: 'pointer',
            padding: 5,
            paddingLeft: '1rem',
            margin: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#9945ff',
            color: '#fff',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
          }),
        }}
      />
    </>
  )
}

export default DropdownChoose
