import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import AddTektools from './AddTektools/AddTektools'
import Header from './Header/HeaderAdmin'
import Sidebar from './Sidebar/Sidebar'

const AdminMain = () => {
  const [serverId, setServerId] = useState(null)
  const [validServer, setValidServer] = useState(true)

  console.log(serverId)
  console.log(validServer)

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/roles/${serverId}`
        )
        const data = await response.json()
        if (data.statusCode === 404) {
          setValidServer(false)
        } else {
          setValidServer(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchRoles()
  }, [serverId])

  return (
    <div className="flex flex-col h-screen bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left font-outfit ">
      <Header />
      <div className="flex flex-1 overflow-hidden w-full">
        <div className="w-64 ">
          <Sidebar setServerId={setServerId} />
        </div>
        <div className=" flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic pt-8">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AdminMain
