import { Button, Input, Option, Select } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ListTraitsReveneuSharing from './ListTraitsReveneuSharing'

const AddTrait = ({ creatorAddress, traitList, onTraitRewardsChange }) => {
  const [traits, setTraits] = useState([])
  const [selectedTraitType, setSelectedTraitType] = useState('')
  const [selectedTraitValue, setSelectedTraitValue] = useState('')
  const [rewardValue, setRewardValue] = useState('')
  const [traitRewards, setTraitRewards] = useState([])

  useEffect(() => {
    if (creatorAddress) {
      const fetchTraits = async () => {
        const apiUrlTraits = `${process.env.REACT_APP_API_BASE_URL}/collections/${creatorAddress}/traits`
        const response = await fetch(apiUrlTraits)
        const data = await response.json()
        setTraits(data)
      }
      fetchTraits()
    }
  }, [creatorAddress])

  useEffect(() => {
    onTraitRewardsChange(traitRewards)
  }, [traitRewards, onTraitRewardsChange])

  return (
    <>
      <section>
        <div className="flex justify-end items-end gap-x-5">
          <Select
            variant="outlined"
            size="lg"
            color="deep-purple"
            className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg"
            textColor="white"
            containerProps={{
              className: 'bg-third rounded-lg text-white font-bold',
            }}
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
            }}
            label="Select Trait Type"
            onChange={(value) => {
              const selected = traits.find((trait) => trait.TraitType === value)
              setSelectedTraitType(selected.TraitType)
            }}
          >
            {traits.length > 0 ? (
              traits.map((trait) => (
                <Option key={trait.TraitType} value={trait.TraitType}>
                  {trait.TraitType}
                </Option>
              ))
            ) : (
              <Option value="no trait">No Trait</Option>
            )}
          </Select>
          <Select
            variant="outlined"
            size="lg"
            color="deep-purple"
            className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg"
            textColor="white"
            containerProps={{
              className: 'bg-third rounded-lg text-white font-bold',
            }}
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
            }}
            label="Select Trait Value"
            onChange={(value) => {
              setSelectedTraitValue(value)
            }}
          >
            {traits.length > 0 ? (
              traits
                .filter((trait) => trait.TraitType === selectedTraitType)
                .flatMap((trait) => trait.TraitValue) // Flatten the array of TraitValue
                .map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))
            ) : (
              <Option value="no trait">No Trait</Option>
            )}
          </Select>
          <Input
            label="Enter the reward for your trait*"
            placeholder="Example: 0.03"
            variant="outlined"
            color="deep-purple"
            size="lg"
            className="focus:outline-none active:border-spacing-1  bg-third focus:bg-basic w-full text-white px-2 tracking-widest font-bold text-xl"
            containerProps={{
              className: 'bg-third rounded-lg text-white font-bold text-lg',
            }}
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text- placeholder-shown:text-white text-white font-bold ',
            }}
            value={rewardValue}
            onChange={(e) => setRewardValue(e.target.value)}
          />
          <div className="flex h-full items-end">
            <Button
              className="bg-accentMain text-white px-10 h-full shadow  hover:bg-accentHouver mt-5"
              onClick={() => {
                const newTraitReward = {
                  TraitName: selectedTraitType,
                  TraitValue: selectedTraitValue,
                  TraitReward: rewardValue,
                }
                if (
                  newTraitReward.TraitName &&
                  newTraitReward.TraitValue &&
                  newTraitReward.TraitReward
                ) {
                  setTraitRewards([...traitRewards, newTraitReward])
                } else {
                  toast.error('Please fill in all fields')
                }
              }}
            >
              ADD
            </Button>
          </div>
        </div>
      </section>
      {traitRewards.length > 0 && (
        <ListTraitsReveneuSharing listTraits={traitRewards} />
      )}
    </>
  )
}

export default AddTrait
