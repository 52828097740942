import React from 'react'
import LoadVotingSystem from './LoadVotingSystem'
import CreateVotingSystem from './CreateVotingSystem'

const VotingSystem = () => {
  return (
    <>
      <section className='container mx-auto text-white'>
        <div className='flex flex-col h-screen overflow-y-auto gap-y-5 scrollbar-thin scrollbar-thumb-second scrollbar-track-basic'>
          {/* titulo */}
          <div className='flex flex-col gap-y-3'>
            <h1 className="text-4xl font-bold">Voting System</h1>
            <h2 className="text-2xl font-medium">Position that the user must have to be able to vote.</h2>
          </div>
          <hr className="h-px mb-5 bg-gray border-0" />
          <div className='flex items-center justify-center'><LoadVotingSystem /></div>
          <CreateVotingSystem />
        </div>
      </section>
    </>
  )
}

export default VotingSystem