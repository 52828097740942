import { Button } from '@material-tailwind/react'
import React from 'react'
import { BiEdit } from 'react-icons/bi'

const ButtonEdit = ({ action }) => {
  return (
    <Button
      onClick={action}
      variant="text"
      className="flex h-full w-full text-white"
    >
      <BiEdit className="w-5 h-5" />
    </Button>
  )
}

export default ButtonEdit
