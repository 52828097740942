import React, { useState } from 'react'
import TokenSetup from './_components/TokenSetup'
import NftSetup from './_components/NftSetup'
import RoleSetup from './_components/RoleSetup'
import token from '../../img/TOKEN.png'
import nft from '../../img/NFT.png'
import {
  Stepper,
  Step,
  Button,
  Spinner,
  Dialog
} from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';
import tek from '../../img/AvatarTektools.png'
import { motion } from 'framer-motion';

const CreateHolderVerification = () => {
  const [selectTypeHolder, setSelectTypeHolder] = useState(false)
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [roleToken, setRoleToken] = useState(null)
  const [roleNft, setRoleNft] = useState(null)
  const [quantityToken, setQuantityToken] = useState(1)
  const [creatorAddress, setCreatorAddress] = useState(null)
  const [collectionName, setCollectionName] = useState(null)
  const [rolePerQty, setRolePerQty] = useState(null)
  const [masterAddress, setMasterAddress] = useState(null)
  const [hashlist, setHashlist] = useState(null)
  const [hashlistFile, setHashlistFile] = useState(null)
  const [skipRole, setSkipRole] = useState(null)
  const [loading, setLoading] = useState(false);
  const [doneSync, setDoneSync] = useState(false);
  const [openDoneSync, setOpenDoneSync] = useState(false);
  const [syncData, setSyncData] = useState(null);
  const [roleAttribute, setRoleAttribute] = useState(null);
  const [isListings, setIsListings] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRoleTokenSelect = (selectedRole) => {
    setRoleToken(selectedRole)
  }
  const handleRoleNftSelect = (selectedRole) => {
    setRoleNft(selectedRole)
  }
  const handleQuantityTokenSelect = (quantity) => {
    setQuantityToken(quantity)
  }

  const handleTokenAddress = (tokenAddress) => {
    setCreatorAddress(tokenAddress)
  }

  const handleCollectionName = (collectionName) => {
    setCollectionName(collectionName)
  }

  const handleMasterAddress = (masterAddress) => {
    setMasterAddress(masterAddress)
  }

  const handleHashlist = (hashlist) => {
    setHashlist(hashlist)
  }

  const handleSkipRole = (skipRole) => {
    setSkipRole(skipRole)
  }

  const handleHashlistFile = (hashlistFile) => {
    setHashlistFile(hashlistFile)
  }

  const handleRolesQuantity = (rolesQuantity) => {
    setRolePerQty(rolesQuantity)
  }

  const handleRolesAttribute = (rolesAttribute) => {
    setRoleAttribute(rolesAttribute)
  }

  const handleIsListings = (isListings) => {
    setIsListings(isListings)
  }

  const handleIsSales = (isSales) => {
    setIsSales(isSales)
  }

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);



  const handleDefinitionStep2 = async () => {
    setLoading(true);
    if (collectionName && creatorAddress && (selectTypeHolder ? roleToken.id : roleNft.id)) {
      const infoDefinition = {
        guildId: localStorage.getItem('selectedServer'),
        isToken: selectTypeHolder,
        minimumQuantity: selectTypeHolder ? quantityToken : 0,
        collectionName: collectionName,
        creatorsAddress: creatorAddress,
        hashlist: hashlist ? [hashlistFile] : [],
        holderRole: selectTypeHolder ? roleToken.id : roleNft.id,
        skipRoleManagement: skipRole,
      }
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/definition`
      console.log('infoDefinition', infoDefinition)
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(infoDefinition),
        });

        if (!response.ok) {
          if (response.status === 409) {
            throw new Error('A collection with this address already exists.');
          }
          throw new Error('Error on response');
        }

        const text = await response.text();
        const data = text ? JSON.parse(text) : {};
        console.log('data', data);
        setSyncData(data.length === 0 ? null : data);
        setLoading(false);
        setOpenDoneSync(true);
        toast.success('Data sync successfully!');
      } catch (error) {
        console.error('Fetch error:', error);
        toast.error(error.message);
        setLoading(false);
      }
    } else {
      toast.error('Ops, something gone wrong.');
      setLoading(false);
      return;
    }
  }

  const nextSync = () => {
    handleNext();
    setDoneSync(true);
  }

  const handleRolesStep3 = async () => {
    if (syncData) {
      const infoRoles = {
        guildId: localStorage.getItem('selectedServer'),
        creatorsAddress: creatorAddress,
        holderVerificationChannelId: selectTypeHolder ? roleToken.id : roleNft.id,
        rolePerAttribute: roleAttribute,
        rolePerQty: rolePerQty,
        listing: isListings,
        sales: isSales,
      }
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/roles`
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(infoRoles),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const text = await response.text()
        const data = text ? JSON.parse(text) : {}
        toast.success('List of configurations sent successfully!')
        successConfig()
      } catch (error) {
        console.error('Errr send configurations:', error)
        toast.error(`Opss, something gone wrong: ${error.message}`)
      }
    } else {
      toast.error('Ops...')
      return
    }
  }

  const successConfig = () => {
    setSuccess(true)
  }

  return (
    <>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
      <section className='text-white shadow-inherit bg-dark/10 h-full rounded-sm'>
        {
          loading &&
          <div className='absolute w-full h-full bg-dark/60 z-40 flex items-center justify-center'>
            <Spinner className='w-12 h-12 text-main/50' />
          </div>
        }
        <Dialog
          className="flex flex-col bg-dark gap-y-5 z-50"
          color='dark'
          size="xs"
          animate={{
            mount: { y: 0 },
            unmount: { y: -200 },
          }}
          open={openDoneSync} handler={() => setOpenDoneSync(false)}
        >
          <span className='text-3xl font-bold'>Sync data successfully!</span>
          <span className='text-xl'>Now you can configure your collection</span>
          <Button className='bg-main' onClick={() => {
            nextSync();
            setOpenDoneSync(false);
          }} disabled={isLastStep}>
            Next
          </Button>
        </Dialog>
        <Dialog
          className="flex flex-col bg-dark gap-y-5 z-50"
          color='dark'
          size="xs"
          animate={{
            mount: { y: 0 },
            unmount: { y: -200 },
          }}
          open={success} handler={() => setSuccess(false)}>
          <span className='text-3xl font-bold'>Sync data successfully!</span>
          <span className='text-xl'>Now you can configure your collection</span>
          <Button className='bg-main' onClick={() => {
            setSuccess(false);
            window.location.reload();
          }}>
            Done
          </Button>
        </Dialog>

        <h1 className='flex p-5 text-3xl font-bold w-full bg-dark'>Holder Verification</h1>
        <div className='flex flex-col container mx-auto gap-y-5 py-5'>
          {/* Content */}
          <div className='flex flex-col container mx-auto gap-y-5'>
            {/* Set Type Holder: NFT or TOKEN */}
            {activeStep === 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                exit={{ opacity: 0 }}
                className='flex flex-col w-full h-full gap-y-3'>
                <span className='text-lg font-bold'>Select the wallet type to configure your collection</span>
                <div className='flex w-full h-full flex-row  '>
                  <div className='relative w-full flex py-2 mx-8 items-center justify-center bg-dark rounded-lg shadow-inner'>
                    <div className={`${selectTypeHolder ? 'grayscale' : 'scale-[1.02]'} hover:scale-[1.02] -mr-[58px]  transition-all duration-700 rounded-l-lg flex relative `}
                      onClick={() => setSelectTypeHolder(false)}>
                      <img src={nft} alt="NFT" className='rounded-l-lg  ' />
                      <span className={`${selectTypeHolder ? 'ml-40' : 'ml-5'} absolute bottom-36 left-1/2 transform -translate-x-1/2 translate-y-full text-5xl bg-dark w-full font-bold py-2 px-4 rounded-tr-xl rounded-bl-xl color-change transition-all duration-300 flex items-center gap-x-2`}>NFT <img src={tek} alt="Tektools" className={`${!selectTypeHolder ? 'w-12 h-12 ' : 'hidden'} transition-all duration-700`} /></span>
                    </div>

                    <div className={`${selectTypeHolder ? 'scale-[1.02]' : 'grayscale  '} hover:scale-[1.02] -ml-[58px] transition-all duration-700 rounded-r-lg flex relative `}
                      onClick={() => setSelectTypeHolder(true)}>
                      <img src={token} alt="Token" className='rounded-r-lg ' />
                      <span className={`${selectTypeHolder ? 'ml-1 w-[88%] pl-10' : 'w-[65%] -ml-8'} text-end absolute top-32 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-5xl bg-dark  font-bold py-2 px-4 rounded-tr-xl rounded-bl-xl color-change transition-all duration-300 flex items-center gap-x-2`}><img src={tek} alt="Tektools" className={`${selectTypeHolder ? 'h-12 w-12 ' : 'hidden'} transition-all duration-300`} />TOKEN</span>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}

            {/* Define attributes */}
            {activeStep === 1 && (
              <motion.div
                initial={{ opacity: 0, x: 1 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0 }}
                className='flex flex-col container mx-auto py-10 gap-4'>
                {selectTypeHolder ? (
                  <TokenSetup
                    onRoleSelect={handleRoleTokenSelect}
                    onQuantitySelect={handleQuantityTokenSelect}
                    onTokenAddress={handleTokenAddress}
                    onCollectionName={handleCollectionName}
                    onSkipRole={handleSkipRole}
                  />
                ) : (
                  <NftSetup
                    onRoleSelect={handleRoleNftSelect}
                    onQuantitySelect={handleQuantityTokenSelect}
                    onTokenAddress={handleTokenAddress}
                    onCollectionName={handleCollectionName}
                    onMasterAddress={handleMasterAddress}
                    onHashlist={handleHashlist}
                    onSkipRole={handleSkipRole}
                    onHashlistFile={handleHashlistFile}
                  />
                )}
              </motion.div>
            )}

            {/* Define notifications */}
            {activeStep === 2 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                exit={{ opacity: 0 }}
              >
                <RoleSetup syncData={syncData} onRolesQuantity={handleRolesQuantity} onRolesAttribute={handleRolesAttribute} onListings={handleIsListings} onSales={handleIsSales} />
              </motion.div>
            )}
          </div>
          {/* Stepper  */}
          {!loading &&
            <div className="absolute bottom-20 px-24 left-1/2 transform -translate-x-1/2 w-full">
              {/* Stepper */}
              <div className='py-2'>
                <Stepper
                  lineClassName="bg-white/50"
                  activeLineClassName="bg-accentHouver"
                  activeStep={activeStep}
                  isLastStep={(value) => setIsLastStep(value)}
                  isFirstStep={(value) => setIsFirstStep(value)}
                >
                  <Step
                    className="h-2 w-2 !bg-blue-gray-50 text-white/75 cursor-pointer "
                    activeClassName="ring-0 !bg-main text-white h-4 w-4"
                    completedClassName="!bg-accentHouver text-white "
                    onClick={() => setActiveStep(0)}
                  />
                  <Step
                    className="h-2 w-2 !bg-blue-gray-50 text-white/75 cursor-pointer"
                    activeClassName="ring-0 !bg-main text-white h-4 w-4"
                    completedClassName="!bg-accentHouver text-white"
                    onClick={() => setActiveStep(1)}
                  />
                  <Step
                    className="h-2 w-2 !bg-blue-gray-50 text-white/75 cursor-pointer"
                    activeClassName="ring-0 !bg-main text-white h-4 w-4"
                    completedClassName="!bg-accentHouver text-white "
                    onClick={() => setActiveStep(2)}
                  />
                </Stepper>
              </div>
            </div>
          }
          {/* Buttons */}
          <div className="absolute bottom-0 left-0 w-full h-14 px-5 py-2 bg-dark flex justify-between">
            <Button className='bg-main' onClick={handlePrev} disabled={isFirstStep}>
              Prev
            </Button>
            {activeStep === 0 && (
              <Button className='bg-main' onClick={handleNext} disabled={isLastStep}>
                Next
              </Button>
            )}
            {activeStep === 1 && (
              <div>
                {!doneSync ? (
                  <Button className='bg-main' onClick={handleDefinitionStep2} disabled={isLastStep}>
                    Sync data
                  </Button>
                ) : (
                  <Button className='bg-main' onClick={handleNext} disabled={isLastStep}>
                    apenas avançar
                  </Button>

                )}
              </div>
            )}
            {activeStep === 2 && (
              <Button className='bg-main' onClick={handleRolesStep3}>
                Finish
              </Button>
            )}
          </div>
        </div>
      </section >
    </>
  )
}

export default CreateHolderVerification