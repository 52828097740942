import { Button, Card, Dialog } from '@material-tailwind/react'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { BiEdit } from 'react-icons/bi'
import coin from '../../img/coin.png'
import { EditToken } from './StakingEdit'
import StakingSetup from './StakingSetup'

const StakingCreated = ({ staking, setStaking }) => {
  let [stakingList, setStakingList] = useState([])
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen((cur) => !cur, setStaking(stakingList))
  const guildId = localStorage.getItem('selectedServer')

  useEffect(() => {
    const stakingList = async () => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/staking?guildId=${guildId}`)
        .then((response) => response.json())
        .then((data) => {
          setStakingList(data)
        })
        .catch((error) => {
          console.error('Error fetching server list:', error)
        })
    }
    stakingList()
  }, [])

  return (
    <>
      {/* Card loaded with staking list */}
      <Card
        className="h-80 w-1/2 group cursor-pointer overflow-hidden transition-opacity hover:opacity-90 shadow-xl shadow-accentMain/10 bg-third"
        onClick={handleOpen}
      >
        {stakingList.map((staking, index) => (
          <div
            key={index}
            className="w-full h-full flex flex-row bg-third rounded-lg"
          >
            {/* Token conditions */}
            <div className="w-2/3 h-full flex flex-col bg-second rounded-lg shadow gap-x-2">
              {/* Server name */}
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full flex flex-row items-end gap-x-5 pt-5 pl-5"
              >
                <span className="text-xl font-bold border-b-2 border-third transition-all w-full pl-5">
                  Tektools Dashboard
                </span>
              </motion.div>
              <div className="w-full h-full flex flex-col items-end justify-start gap-y-5">
                <div className="w-20 h-2 flex text-center items-center justify-center bg-third rounded-bl-lg -mr-1 group-hover:w-44 transition-all duration-500"></div>
                <div className="w-full h-full flex flex-row">
                  <div className="flex flex-col w-full items-center justify-center gap-y-5 pl-5">
                    {/* Token name */}
                    <div className="w-full flex flex-row justify-end items-end gap-x-2">
                      <img
                        src={coin}
                        alt="FakeCoin"
                        className="w-12 h-12 animate-spinslow  "
                      />
                      <motion.span
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-xl font-bold w-full"
                      >
                        {staking.TokenName}
                      </motion.span>
                    </div>
                    {/* Specific rewards staked from the collection */}
                    {staking.TraitBased === false ? (
                      <span className="text-xs font-bold">
                        No specific rewards staked
                      </span>
                    ) : (
                      <div className="text-xs font-bold w-auto flex flex-row">
                        {staking.StakedCollection.map((collection, index) => (
                          <span key={index}>
                            Specific rewards staked from the collection{' '}
                            {collection.CollectionName}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center gap-y-5 w-full">
                    {/* Minimum coins withdrawal */}
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row items-end justify-center">
                        <span className="text-2xl font-bold">
                          <CountUp
                            end={staking.WithdrawMinimumBalance}
                            duration={0.7}
                          />
                        </span>
                        <span className="text-[10px] font-bold pb-1">
                          Tokens
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <span className="font-bold text-xs flex">Minimum</span>
                        <span className="font-bold text-xs flex -pt-1">
                          withdrawal
                        </span>
                      </div>
                    </div>
                    {/* Minimum days withdrawal */}
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row items-end justify-center">
                        <span className="text-2xl font-bold">
                          <CountUp
                            end={staking.WithdrawMinimumDays}
                            duration={0.9}
                          />
                        </span>
                        <span className="text-[10px] font-bold pb-1">Days</span>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <span className="font-bold text-xs flex">
                          Withdrawal{' '}
                        </span>
                        <span className="font-bold text-xs flex -pt-1">
                          after
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-col w-full gap-x-2 items-start pl-5 pb-5"
                >
                  <Button
                    color="deep-purple"
                    className="flex flex-row items-center justify-center text-center gap-x-2 w-24 h-10 text-base font-bold px-2  bg-accentMain transition-all duration-500 hover:bg-accentHouver"
                  >
                    <BiEdit className="text-lg" />
                    Edit
                  </Button>
                </motion.div>
              </div>
            </div>
            {/* Token Rewards */}
            {staking.StakedCollection.map((collection, index) => (
              <div
                key={index}
                className="w-1/3 h-full flex flex-col items-center justify-center gap-y-5"
              >
                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="w-30 h-20 p-2 flex flex-col items-center justify-center"
                >
                  <div className="flex flex-row items-end justify-center gap-x-0.5">
                    <span className="text-2xl font-bold">
                      <CountUp end={staking.TotalRewardPerDay} duration={0.7} />
                    </span>
                    <span className="text-[10px] font-bold pb-1">coins</span>
                  </div>
                  <span className="text-xs font-bold text-center flex">
                    Reward{' '}
                  </span>
                  <span className="text-xs font-bold text-center flex -mt-0.5">
                    per day
                  </span>
                </motion.div>

                <div className="w-20 h-20 flex flex-col items-center justify-center ">
                  <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.7 }}
                    className="w-full flex flex-col items-center"
                  >
                    <span className="text-2xl font-bold text-center items-center">
                      <CountUp
                        end={collection.TraitRewards.reduce(
                          (acc, trait) => acc + trait.TraitReward,
                          0
                        )}
                        duration={0.7}
                      />
                    </span>
                    <span className="text-xs font-bold text-center">
                      Traits for reward per day
                    </span>
                    <span className="text-xs font-bold -mt-0.5"></span>
                  </motion.div>
                </div>
                <div className="w-20 h-20 flex flex-col items-center justify-center ">
                  <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.9 }}
                    className="w-full flex flex-col items-center text-center"
                  >
                    <span className="text-2xl font-bold">
                      <CountUp
                        end={collection.TraitRewards.length}
                        duration={0.7}
                      />
                    </span>
                    <span className="text-xs font-bold">Different</span>
                    <span className="text-xs font-bold -mt-0.5">traits</span>
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Card>

      {/* Staking setup dialog */}
      <Dialog
        open={open}
        handler={handleOpen}
        className="flex flex-col  text-white bg-second overflow-y-scroll overflow-hidden"
        size="xl"
        animate={{
          mount: { y: 0 },
          unmount: { y: -200 },
        }}
      >
        <div className=" rounded-lg">
          <EditToken listStaking={staking} />
        </div>
      </Dialog>
    </>
  )
}

export default StakingCreated
