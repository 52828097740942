import React, { useState } from 'react'
import MultiselectRole from '../components/multiselectRole'
import MultiselectChannel from '../components/multiselectChannel'
import { Input, Textarea } from '@material-tailwind/react'

const CreateVotingSystem = () => {
  const [roles, setRoles] = useState([])
  const [channels, setChannels] = useState()
  return (
    <>
      <div className='flex flex-col gap-y-5'>
        {/* Role and channel voting */}
        <div className='flex gap-x-5'>
          <div>Role for voting - multRoles
            <MultiselectRole isMulti={true} onSelectRole={(e) => { setRoles(e) }} />
          </div>
          <div>Channel for voting
            <MultiselectChannel onSelectChannel={(e) => { setChannels(e) }} />
          </div>
        </div>
        {/* Poll question */}
        <div>Poll-question
          <Textarea
            label="Enter new name of Collection*"
            placeholder="Exemple: My Collection."
            variant="outlined"
            color="deep-purple"
            size="lg"
            className="focus:outline-none active:border-spacing-1 bg-third focus:bg-basic text-white px-2 rounded-lg  "
            textColor="white"
            containerProps={{
              className: 'bg-third rounded-lg text-white font-bold ',
            }}
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white px-2',
            }}

          />
        </div>
        <div>
          <div>Poll-options</div>
          <div>List of options</div>
        </div>
        {/* Poll-duration */}
        <div className='flex gap-x-5'>
          <div>Poll duration time</div>
          <div>Hours or days</div>
        </div>
      </div>
    </>
  )
}

export default CreateVotingSystem