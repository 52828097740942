import { Button, Input, Option, Select } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md'
import MultiselectRole from '../../components/multiselectRole'

// Alterado: Convertido os valores para string
const optionsRole = [
  { label: 'Immediate', value: '0' },
  { label: 'After 7 days', value: '7' },
  { label: 'After 30 days', value: '30' },
]

const ConditionsRewards = ({ setConditions, newCondition }) => {
  const [roleAbleToStake, setRoleAbleToStake] = useState([])

  const [valueRewardPerDay, setValueRewardPerDay] = useState(
    setConditions.length > 0 ? setConditions[0].RewardPerDay : 0
  )
  const [valueMinWithdrawal, setValueMinWithdrawal] = useState(
    setConditions.length > 0 ? setConditions[0].MinimumWithdrawal : 0
  )
  const [valueMinDays, setValueMinDays] = useState(
    setConditions.length > 0 ? setConditions[0].MinimumDays : '0' // Alterado: Valor inicial como string
  )

  const incrementRewardPerDay = () =>
    setValueRewardPerDay(valueRewardPerDay + 1)
  const decrementRewardPerDay = () => {
    if (valueRewardPerDay <= 1) {
      setValueRewardPerDay(1)
    } else {
      setValueRewardPerDay(valueRewardPerDay - 1)
    }
  }

  const incrementMinWithdrawal = () =>
    setValueMinWithdrawal(valueMinWithdrawal + 1)
  const decrementMinWithdrawal = () => {
    if (valueMinWithdrawal <= 1) {
      setValueMinWithdrawal(1)
    } else {
      setValueMinWithdrawal(valueMinWithdrawal - 1)
    }
  }

  useEffect(() => {
    const condition = {
      RewardPerDay: valueRewardPerDay,
      MinimumWithdrawal: valueMinWithdrawal,
      MinimumDays: parseInt(valueMinDays), // Alterado: Converter para número antes de enviar
    }
    newCondition(condition)
  }, [valueRewardPerDay, valueMinWithdrawal, valueMinDays, newCondition])

  return (
    <section className="flex flex-col h-full w-[70%] gap-y-5 text-white justify-center">
      <MultiselectRole isMulti={true} onSelectRole={setRoleAbleToStake} />

      <div className="flex flex-col">
        <div className="flex items-end transition duration-300">
          <div className="h-full w-10 flex flex-col gap-y-0.5 items-center justify-center focus:border-mainHover">
            <Button
              onClick={decrementRewardPerDay}
              className="bg-mainHover p-3 w-full h-full rounded-r hover:bg-mainHover/50"
            >
              <MdOutlineRemove />
            </Button>
          </div>
          <Input
            placeholder="teste"
            label="Reward per day"
            type="number"
            color="deep-purple"
            min={1}
            value={valueRewardPerDay}
            onChange={(e) => setValueRewardPerDay(parseInt(e.target.value))}
            variant="outlined"
            size="lg"
            className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
            }}
          />
          <div className="h-full w-10 flex flex-col gap-y-0.5 items-center justify-center">
            <Button
              onClick={incrementRewardPerDay}
              className="bg-mainHover p-3 w-full h-full rounded-l"
            >
              <MdOutlineAdd />
            </Button>
          </div>
        </div>
      </div>

      <div className="flex items-end transition duration-300">
        <div className="h-full w-10 flex flex-col gap-y-0.5 items-center justify-center focus:border-mainHover">
          <Button
            onClick={decrementMinWithdrawal}
            className="bg-mainHover p-3 w-full h-full rounded-r"
          >
            <MdOutlineRemove />
          </Button>
        </div>
        <Input
          label="Minimum withdrawal"
          type="number"
          color="deep-purple"
          min={1}
          value={valueMinWithdrawal}
          onChange={(e) => setValueMinWithdrawal(parseInt(e.target.value))}
          variant="outlined"
          size="lg"
          className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
          labelProps={{
            className:
              'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
          }}
        />
        <div className="h-full w-10 flex flex-col gap-y-0.5 items-center justify-center">
          <Button
            onClick={incrementMinWithdrawal}
            className="bg-mainHover p-3 w-full h-full rounded-l text"
          >
            <MdOutlineAdd />
          </Button>
        </div>
      </div>

      <Select
        color="deep-purple"
        variant="outlined"
        size="lg"
        className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
        labelProps={{
          className:
            'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
        }}
        menuProps={{
          className: 'bg-basic border-none',
        }}
        label="Minimum amount of days*"
        value={valueMinDays}
        onChange={(e) => setValueMinDays(e)}
      >
        {optionsRole.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            className="flex hover:bg-second active:bg-red-400 text-white p-1.5 pl-5 hover:pl-6 rounded-sm transition-all duration-300"
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </section>
  )
}

export default ConditionsRewards
