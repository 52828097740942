import React from 'react'

const ListTraitsReveneuSharing = ({ listTraits }) => {
  return (
    <div
      className={`w-full overflow-y-auto shadow-md rounded-lg border border-second scrollbar-thin scrollbar-thumb-second scrollbar-track-basic`}
    >
      <table className="w-full text-left h-full">
        <thead className="text-xs bg-basic uppercase">
          <tr>
            <th scope="col" className="hidden p-3">
              ID
            </th>
            <th scope="col" className="p-3">
              Trait
            </th>
            <th scope="col" className="p-3">
              Value
            </th>
            <th scope="col" className=" p-3">
              Reward
            </th>
          </tr>
        </thead>
        <tbody className="overflow-y-hidden  mx-auto ">
          {listTraits.map((trait, index) => (
            <tr
              key={index}
              className="bg-third hover:bg-third/70 border-second border-t font-light"
            >
              <td className="px-3 py-2 border-r border-second">
                {trait.TraitName}
              </td>
              <td className="px-3 py-2 border-r border-second">
                {trait.TraitValue}
              </td>
              <td className="px-3 py-2 border-r border-second">
                {trait.TraitReward}
              </td>
              <td className="hidden px-3 p-2 border-r border-second">
                {trait._id}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ListTraitsReveneuSharing
